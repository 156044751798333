export enum Locales {
    de = 'de',
    en = 'en',
    enGb = 'en-gb',
    es = 'es',
    fr = 'fr',
    it = 'it',
    ja = 'ja',
    ko = 'ko',
    nl = 'nl',
    no = 'no',
    pl = 'pl',
    ru = 'ru',
    zh = 'zh',
}
