import {
    changeActiveVariants,
    fetchActiveExperimentVariants,
    resetVariants,
    storeVisitorCode,
} from './kameleoon.actions';
import { createSlice } from '@reduxjs/toolkit';
import { defaultKameleoonSlice } from './kameleoon.types';

const kameleoonSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(fetchActiveExperimentVariants.fulfilled, (slice, { payload }) => {
            slice.activeVariants = payload;
            slice.userSelectedVariants = payload;
            slice.userChangedVariant = false;
        });
        builder.addCase(changeActiveVariants.fulfilled, (slice, { payload }) => {
            slice.userSelectedVariants = payload;
            slice.userChangedVariant = true;
        });
        builder.addCase(resetVariants.fulfilled, (slice) => {
            slice.userSelectedVariants = slice.activeVariants;
            slice.userChangedVariant = false;
        });
        builder.addCase(storeVisitorCode.fulfilled, (slice, { payload }) => {
            slice.visitorCode = payload;
        });
    },
    initialState: defaultKameleoonSlice,
    name: 'kameleoon',
    reducers: {},
});

export const { reducer: kameleoonReducer } = kameleoonSlice;
