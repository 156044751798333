import { AppDispatch, AppGetState, GlobalState } from '@/redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { getDeployment } from './config';
import { postForgotPassword } from '@/redux/api/auth';
import ms from 'ms';

import { REHYDRATE } from 'redux-persist/constants';

/* Action Types */
export const FORGOT_PASSWORD_FAIL = 'la/ui/forgotPassword/FAIL';
export const FORGOT_PASSWORD_REQUEST = 'la/ui/forgotPassword/REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'la/ui/forgotPassword/SUCCESS';

// reducer
export type ForgotPasswordSlice = {
    error: boolean;
    errorMessage: string | null;
    submitted: boolean;
    success: boolean;
    timestamps: {};
};

export const defaultForgotPasswordSlice: ForgotPasswordSlice = {
    error: false,
    errorMessage: null,
    submitted: false,
    success: false,
    timestamps: {},
};

export default function reducer(
    state: ForgotPasswordSlice = defaultForgotPasswordSlice,
    action: any = {}
): ForgotPasswordSlice {
    switch (action.type) {
        case REHYDRATE:
            if (Object.prototype.hasOwnProperty.call(action.payload, 'forgotPassword')) {
                const { timestamps } = action.payload.forgotPassword;
                return { ...state, timestamps };
            }

            return state;
        case FORGOT_PASSWORD_REQUEST:
            return {
                ...state,
                error: false,
                errorMessage: null,
                submitted: true,
                success: false,
            };
        case FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                submitted: false,
                success: true,
                timestamps: {
                    [action.payload.emailAddress]: action.meta.actionTime,
                },
            };
        case FORGOT_PASSWORD_FAIL:
            return {
                ...state,
                error: true,
                errorMessage: action.payload.message,
                submitted: false,
                success: false,
            };
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state: GlobalState) => state;
const idSelector = (_: GlobalState, id: string) => id;
export const getUiForgotPassword = createSelector(stateSelector, (state) => state.forgotPassword);
const getForgotPasswordTimestamps = createSelector(getUiForgotPassword, (state) => state.timestamps);

export const getUiForgotPasswordSuccess = createSelector(getUiForgotPassword, (state) => state.success);
export const getUiForgotPasswordSubmitted = createSelector(getUiForgotPassword, (state) => state.submitted);
export const getUiForgotPasswordError = createSelector(getUiForgotPassword, (state) => state.error);
export const getUiForgotPasswordErrorMessage = createSelector(getUiForgotPassword, (state) => state.errorMessage);

const getIsAtLeast5MinutesSinceLastRequest = createSelector(
    [getForgotPasswordTimestamps, idSelector],
    (timestamps, id) => Date.now() - (timestamps[id] || 0) >= ms('5m')
);

/* ACTION CREATORS */
export const submitForgotPassword =
    (emailAddress: string, sendSMS: boolean) => async (dispatch: AppDispatch, getState: AppGetState) => {
        try {
            const state = getState();
            const deployment = getDeployment(state);

            dispatch({
                payload: { emailAddress, sendSMS },
                type: FORGOT_PASSWORD_REQUEST,
            });

            if (!getIsAtLeast5MinutesSinceLastRequest(state, emailAddress)) {
                return dispatch({
                    error: true,
                    meta: { emailAddress, sendSMS },
                    payload: {
                        message: 'For your account security, please wait 5 min between sending reset messages.',
                    },
                    type: FORGOT_PASSWORD_FAIL,
                });
            }

            await postForgotPassword({ deployment, emailAddress, sendSMS });
            return dispatch({
                meta: { actionTime: Date.now() },
                payload: { emailAddress, sendSMS },
                type: FORGOT_PASSWORD_SUCCESS,
            });
        } catch (error) {
            return dispatch({
                error: true,
                meta: { emailAddress, sendSMS },
                payload: error,
                type: FORGOT_PASSWORD_FAIL,
            });
        }
    };
