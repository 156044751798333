import { AppDispatch, AppGetState, GlobalState } from '@/redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { getDeployment } from './config';
import api, { ListingAgent } from '@/redux/api/item';
import cloneDeep from 'lodash/cloneDeep';
import difference from 'lodash/difference';
import ms from 'ms';
import union from 'lodash/union';

/* Action Types */
export const LOAD_LISTING_AGENT_FAIL = 'la/domain/listingAgent/LOAD_FAIL';
export const LOAD_LISTING_AGENT_REQUEST = 'la/domain/listingAgent/LOAD_REQUEST';
export const LOAD_LISTING_AGENT_SUCCESS = 'la/domain/listingAgent/LOAD_SUCCESS';

const REDUX_STORE_TIME = ms('30m');

/* reducer */
export type ListingAgentSlice = {
    byId: { [agentId: number]: ListingAgent };
    loaded: { [agentId: number]: number };
    loading: number[];
};

export const defaultListingAgentSlice: ListingAgentSlice = {
    byId: {},
    loaded: {},
    loading: [],
};

export default function reducer(state = defaultListingAgentSlice, action: any = {}): ListingAgentSlice {
    let existing: ListingAgentSlice['byId'];
    let loaded: ListingAgentSlice['loaded'];
    let loading: ListingAgentSlice['loading'];
    let time: number;

    switch (action.type) {
        case LOAD_LISTING_AGENT_FAIL:
            return {
                ...state,
                loading: difference(state.loading, action.meta.agentIds),
            };
        case LOAD_LISTING_AGENT_REQUEST:
            return {
                ...state,
                loading: union(state.loading, action.payload),
            };
        case LOAD_LISTING_AGENT_SUCCESS:
            existing = cloneDeep(state.byId);
            loaded = { ...state.loaded };
            loading = cloneDeep(state.loading);
            time = action.meta.actionTime;

            if (action.payload.listingAgents) {
                action.payload.listingAgents.forEach((x) => {
                    existing[x.agentId] = { ...x };
                    loaded[x.agentId] = time;
                    loading = difference(loading, [x.agentId]);
                });
            }

            return {
                ...state,
                byId: existing,
                loaded,
                loading,
            };
        default:
            return state;
    }
}

export const emptyListingAgent: ListingAgent = {
    agentId: 0,
    country: '',
    state: '',
};

/* SELECTORS */
const stateSelector = (state: GlobalState) => state;
const listingAgentSlice = createSelector(stateSelector, (state) => state.listingAgent);

const idSelector = (_: GlobalState, id: number) => id;

const byIdSelector = createSelector(listingAgentSlice, (state) => state.byId);

const loadedSelector = createSelector(listingAgentSlice, (state) => state.loaded);

const loadingSelector = createSelector(listingAgentSlice, (state) => state.loading);

// takes in a (state, id) and passes both params to every function on
// the way down.
export const getListingAgent = createSelector(
    [byIdSelector, idSelector],
    (byId, id): ListingAgent => byId[id] ?? emptyListingAgent
);

const getLoadTimeForListingAgent = createSelector([loadedSelector, idSelector], (loaded, id) => loaded[id] || 0);

const isListingAgentLoading = createSelector([loadingSelector, idSelector], (loading, id) => loading.includes(id));

const shouldFetchListingAgent = (state, agentId) => {
    if (!agentId) {
        return false;
    }
    const item = getListingAgent(state, agentId);
    if (item) {
        const loaded = getLoadTimeForListingAgent(state, agentId);
        const time = Date.now();
        const diff = time - loaded;
        if (diff < REDUX_STORE_TIME) {
            return false;
        }
    }
    const loading = isListingAgentLoading(state, agentId);
    return !loading;
};

const whichListingAgentsNeeded = (state, agentIds) =>
    agentIds.filter((agentId) => shouldFetchListingAgent(state, agentId));

/* ACTION CREATORS */
const loadListingAgents = (agentIds) => async (dispatch, getState) => {
    try {
        const state = getState();
        const deployment = getDeployment(state);
        dispatch({
            payload: agentIds,
            type: LOAD_LISTING_AGENT_REQUEST,
        });
        const response = await api.fetchListingAgentsByIds({
            agentIds,
            deployment,
        });
        dispatch({
            meta: { actionTime: Date.now(), agentIds },
            payload: response.data,
            type: LOAD_LISTING_AGENT_SUCCESS,
        });
    } catch (error) {
        dispatch({
            error: true,
            meta: { agentIds },
            payload: error,
            type: LOAD_LISTING_AGENT_FAIL,
        });
    }
};

export const fetchListingAgentsIfNeeded =
    (agentIds: number[]) => async (dispatch: AppDispatch, getState: AppGetState) => {
        const needed = whichListingAgentsNeeded(getState(), agentIds);
        if (needed.length) {
            return dispatch(loadListingAgents(needed));
        }
        return Promise.resolve();
    };
