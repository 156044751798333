import { createSelector } from '@reduxjs/toolkit';
import { GlobalState } from '@/redux/store';

const stateSelector = (state: GlobalState) => state;
const itemCarouselSlice = createSelector(stateSelector, (state) => state.itemCarousel);

const idSelector = (_, id: number) => id;

const byIdSelector = createSelector(itemCarouselSlice, (state) => state.byId);

export const getCarousels = createSelector([byIdSelector, idSelector], (byId, id) => byId[id] || []);

export const shouldFetchCarousel = createSelector([itemCarouselSlice, idSelector], (state, key) => {
    return !Boolean(state?.byId[key]) && !state.loading;
});
