import { AuctionStatus } from '@/types/LiveAuctionState';
import { createSelector } from '@reduxjs/toolkit';
import { emptyItemSummary, ItemSummary } from '@/types/item/ItemSummary';
import { getImageUrl, getItemUrl, getSellerUrl, imagePlaceholder } from '@/utils/urls';
import { GlobalState } from '@/redux/store';
import { ITEM_SUMMARY_CACHE_TIME, NextAndPreviousItemResponse } from './itemSummary.types';
import { JASPER52_ID } from '@/utils/houseIds.constants';

const stateSelector = (state: GlobalState) => state;
const itemSummarySlice = createSelector(stateSelector, (state) => state.itemSummary);

const idSelector = (_: GlobalState, id: number) => id;
const secondIdSelector = (_1: GlobalState, _2: number, id: number) => id;
const idsSelector = (_: GlobalState, ids: number[]) => ids;

const byIdSelector = createSelector(itemSummarySlice, ({ byId }) => byId);
const loadedSelector = createSelector(itemSummarySlice, ({ loaded }) => loaded);
const loadingSelector = createSelector(itemSummarySlice, ({ loading }) => loading);

export const getIsItemSummaryLoaded = createSelector([loadedSelector, idSelector], (loaded, id) =>
    loaded.hasOwnProperty(id)
);

export const getAllItemSummaries = createSelector(byIdSelector, (summaries) => summaries);

export const getLoadTimeForItemSummary = createSelector(
    [loadedSelector, idSelector],
    (loaded, itemId) => loaded[itemId] || 0
);

export const getItemSummary = createSelector(
    [byIdSelector, idSelector],
    (byId, itemId) => byId[itemId] || emptyItemSummary
);

export const getItemSummaries = createSelector([byIdSelector, idsSelector], (byId, itemIds) =>
    (itemIds || []).map((itemId: number) => byId[itemId]).filter((item) => Boolean(item))
);

// Used for Hammer UI CardGrid data to render cards.
export const getItemSummaryMap = createSelector([byIdSelector, idsSelector], (byId, itemIds) =>
    (itemIds || []).reduce(
        (acc, itemId: number) => {
            if (byId[itemId]) {
                // Add card-itemId to the item summary to be used to control hover states
                acc[itemId] = { ...byId[itemId], id: `card-${itemId}` };
            }
            return acc;
        },
        {} as Record<number, ItemSummary & { id?: string }>
    )
);

export const getItemSummaryIsPlacingBid = createSelector(
    getItemSummary,
    ({ bidderIsPlacingBid }) => bidderIsPlacingBid
);

export const getIsExtended = createSelector(
    getItemSummary,
    (item) => item && item.isTimedPlusAuction && item.isExtended
);
export const getItemCurrentTimedPlusEndTime = createSelector(
    getItemSummary,
    /**
     * @returns Timed+ Item's end time in epoch seconds
     */
    ({ lotEndTimeEstimatedTs }) => lotEndTimeEstimatedTs
);

export const getIsTimedPlus = createSelector(getItemSummary, (item) => Boolean(item?.isTimedPlusAuction));
export const getItemModelBidActivityCount = createSelector(getItemSummary, ({ bidActivityCount }) => bidActivityCount);
export const getItemModelBidCount = createSelector(getItemSummary, ({ bidCount }) => bidCount);
export const getItemSummaryLeadingBid = createSelector(getItemSummary, ({ leadingBid }) => leadingBid);
export const getItemModelBuyNowStatus = createSelector(getItemSummary, ({ buyNowStatus }) => buyNowStatus);
export const getItemModelBuyNowAvailable = createSelector(getItemSummary, ({ buyNowStatus }) => Boolean(buyNowStatus));
export const getItemModelBuyNowPrice = createSelector(getItemSummary, ({ buyNowPrice }) => buyNowPrice);
export const getItemModelCurrency = createSelector(getItemSummary, ({ currency }) => currency);
export const getItemModelCatalogStatus = createSelector(getItemSummary, ({ catalogStatus }) => catalogStatus);
export const getItemModelCatalogOnly = createSelector(getItemSummary, ({ isCatalogOnly }) => isCatalogOnly);
export const getItemModelCatalogLotsListed = createSelector(getItemSummary, ({ lotsListed }) => lotsListed);
export const getItemModelTitle = createSelector(getItemSummary, ({ title }) => title);
export const getItemModelSEOExcluded = createSelector(getItemSummary, ({ isSEOExcluded }) => Boolean(isSEOExcluded));
export const getItemModelLotNumber = createSelector(getItemSummary, ({ lotNumber }) => lotNumber);
export const getItemPhotos = createSelector(getItemSummary, ({ photos }) => photos || []);
export const getItemNumPhotos = createSelector(getItemPhotos, (photos) => photos?.length);
export const getItemFirstImageId = createSelector(getItemPhotos, (photos) => photos?.[0]);
export const getItemIndex = createSelector(getItemSummary, ({ index }) => index);
export const getItemImageVersion = createSelector(getItemSummary, ({ imageVersion }) => imageVersion);
export const getItemModelHighBidEstimate = createSelector(getItemSummary, ({ highBidEstimate }) => highBidEstimate);
export const getItemModelLowBidEstimate = createSelector(getItemSummary, ({ lowBidEstimate }) => lowBidEstimate);
export const getItemModelStartPrice = createSelector(getItemSummary, ({ startPrice }) => startPrice);
export const getItemModelSalePrice = createSelector(getItemSummary, ({ salePrice }) => salePrice);
export const getItemModelSlug = createSelector(getItemSummary, ({ slug }) => slug);
export const getItemModelIsAvailable = createSelector(getItemSummary, ({ isAvailable }) => Boolean(isAvailable));
export const getItemModelIsSold = createSelector(getItemSummary, ({ isSold }) => Boolean(isSold));
export const getItemModelIsLocked = createSelector(getItemSummary, ({ isLocked }) => Boolean(isLocked));
export const getItemModelIsPassed = createSelector(getItemSummary, ({ isPassed }) => Boolean(isPassed));
export const getItemModelIsPaused = createSelector(getItemSummary, ({ isPaused }) => Boolean(isPaused));
export const getItemModelPromoted = createSelector(getItemSummary, ({ promoted }) => Boolean(promoted));
export const getItemModelCatalogId = createSelector(getItemSummary, ({ catalogId }) => catalogId);
export const getItemModelCatalogTitle = createSelector(getItemSummary, ({ catalogTitle }) => catalogTitle);
export const getItemModelSellerId = createSelector(getItemSummary, ({ sellerId }) => sellerId);
export const getItemModelSellerIsJasper52 = createSelector(getItemSummary, ({ sellerId }) => sellerId === JASPER52_ID);
export const getItemModelSellerName = createSelector(getItemSummary, ({ sellerName }) => sellerName);
export const getItemModelSimilarItemsUrl = createSelector(getItemSummary, ({ similarItemsURL }) => similarItemsURL);
export const getItemModelShortDescription = createSelector(getItemSummary, ({ shortDescription }) => shortDescription);
export const getItemModelStartTime = createSelector(getItemSummary, ({ saleStartTs }) => saleStartTs);
export const getItemSummaryBidderHasBid = createSelector(getItemSummary, ({ bidderHasBid }) => bidderHasBid);
export const getItemSummaryBidderMaxBid = createSelector(getItemSummary, ({ bidderMaxBid }) => bidderMaxBid);
export const getItemSummaryBidderWonItem = createSelector(getItemSummary, ({ bidderWonItem }) => bidderWonItem);
export const getItemSummaryBidderHasHighBid = createSelector(
    getItemSummary,
    ({ bidderHasHighBid }) => bidderHasHighBid
);

export const getItemSummaryLotLocation = createSelector(
    getItemSummary,
    ({ lotLocation }) => lotLocation || emptyItemSummary.lotLocation
);

export const getItemSummaryHasLiveShipping = createSelector(getItemSummary, ({ hasLiveShipping }) => hasLiveShipping);

export const getItemSummaryHasFreeShipping = createSelector(getItemSummary, ({ hasFreeShipping }) => hasFreeShipping);

export const getItemSummaryHasFlatRateShipping = createSelector(
    getItemSummary,
    ({ hasFlatRateShipping }) => hasFlatRateShipping
);

export const getItemSummaryFlatRateShippingAmount = createSelector(
    getItemSummary,
    ({ flatRateShippingAmount }) => flatRateShippingAmount ?? 0
);

export const getItemModelUrl = createSelector([getItemModelTitle, idSelector], (title, itemId) =>
    getItemUrl(itemId, title)
);

export const getItemModelSellerUrl = createSelector([getItemModelSellerName, getItemModelSellerId], (name, sellerId) =>
    getSellerUrl(sellerId, name)
);

export const getIsTimedPlusItemBiddingClosed = createSelector(
    [getIsTimedPlus, getItemCurrentTimedPlusEndTime, getItemModelIsSold, getItemModelIsPassed],
    (isTimedPlusAuction, lotEndTimeEstimatedTs, isSold, isPassed) =>
        (isTimedPlusAuction && lotEndTimeEstimatedTs < Date.now() / 1000) || isSold || isPassed
);

export const getIsTimedPlusBiddingOpen = createSelector(
    getItemModelStartTime,
    (saleStartTs) => Boolean(saleStartTs) && Date.now() / 1000 > saleStartTs
);

export const getItemReserveMet = createSelector(getItemSummary, ({ isReserveMet }) => isReserveMet);

export const hasItemSummaryStarted = createSelector(
    getItemModelStartTime,
    (saleStartTs) => Date.now() / 1000 > saleStartTs
);

export const getItemModelIsTimedAuction = createSelector(getItemSummary, ({ isTimedAuction }) =>
    Boolean(isTimedAuction)
);

export const getItemModelIsTimedPlusAuction = createSelector(getItemSummary, ({ isTimedPlusAuction }) =>
    Boolean(isTimedPlusAuction)
);

export const getItemFirstImageUrl = createSelector(
    [getItemModelCatalogId, getItemFirstImageId, getItemImageVersion, getItemModelSellerId, idSelector],
    (catalogId, imageNumber, imageVersion, sellerId, itemId) => {
        if (typeof imageNumber === 'undefined') {
            return imagePlaceholder;
        }
        return getImageUrl({
            catalogId,
            imageNumber,
            imageVersion,
            itemId,
            sellerId,
        });
    }
);

export const getItemImageUrlByImageNumber = createSelector(
    [getItemModelCatalogId, getItemImageVersion, getItemModelSellerId, idSelector, secondIdSelector],
    (catalogId, imageVersion, sellerId, itemId, imageNumber) => {
        return getImageUrl({
            catalogId,
            imageNumber,
            imageVersion,
            itemId,
            sellerId,
        });
    }
);

export const getIsItemSummaryLoading = createSelector([loadingSelector, idSelector], (loading, id) =>
    loading.includes(id)
);

/**
 * @returns number the available shipping provider's ID, or 0 there is no shipping provider
 */
export const getShippingProviderId = createSelector(
    getItemSummary,
    ({ shippingProviderId }) => shippingProviderId || 0
);

export const getItemIdsForCatalog = createSelector([byIdSelector, idSelector], (byId, id): number[] =>
    Object.keys(byId)
        .map((key) => byId[Number(key)])
        .filter((item) => item.catalogId === id)
        .map((item) => item.itemId)
);

export const getItemsForCatalog = createSelector([byIdSelector, idSelector], (byId, id): ItemSummary[] =>
    Object.keys(byId)
        .map((key) => byId[Number(key)])
        .filter((item) => item.catalogId === id)
        .sort((a, b) => a.index - b.index)
);

export const getItemIdsForCatalogWithMostBids = createSelector([byIdSelector, idSelector], (byId, id): number[] =>
    Object.keys(byId)
        .map((key) => byId[Number(key)])
        .filter((item) => item.catalogId === id)
        .sort((a, b) => b.bidActivityCount - a.bidActivityCount)
        .map((item) => item.itemId)
        .slice(0, 10)
);

export const getItemIdsForSeller = createSelector([byIdSelector, idSelector], (byId, id): number[] =>
    Object.keys(byId)
        .map((key) => byId[key])
        .filter((item) => item.sellerId === id)
        .map((item) => item.itemId)
);

export const getNextAndPreviousItems = createSelector(
    [byIdSelector, idSelector],
    (byId, itemId): NextAndPreviousItemResponse => {
        const currentItem = byId[itemId];
        if (!Boolean(currentItem)) {
            return {
                next: 0,
                previous: 0,
            };
        }
        const { catalogId, index, lotsListed } = currentItem;

        const items = Object.keys(byId)
            .map((key) => byId[key])
            .filter((item) => item.catalogId === catalogId);

        let previous: number;
        let next: number;

        if (index === 0) {
            next = items.find((item) => item.index === index + 1)?.itemId;
        } else if (index === lotsListed - 1) {
            previous = items.find((item) => item.index === index - 1)?.itemId;
        } else {
            previous = items.find((item) => {
                return item.index === index - 1;
            })?.itemId;
            next = items.find((item) => {
                return item.index === index + 1;
            })?.itemId;
        }

        return {
            next: next || 0,
            previous: previous || 0,
        };
    }
);

export const getNextItemSummary = createSelector([byIdSelector, idSelector], (byId, itemId) => {
    const currentItem = byId[itemId];

    let next: ItemSummary = emptyItemSummary;

    if (!Boolean(currentItem)) {
        return next;
    }

    const { catalogId, index, lotsListed } = currentItem;
    const items = Object.keys(byId)
        .map((key) => byId[key])
        .filter((item) => item.catalogId === catalogId);

    if (index >= 0 && lotsListed - 1 > index) {
        next = items.find((item) => item.index === index + 1) || emptyItemSummary;
    }

    return next;
});

export const shouldFetchItem = (state: GlobalState, itemId: number): boolean => {
    if (!itemId) {
        return false;
    }
    const item = getItemSummary(state, itemId);
    // item.index === 9999 indicates that it was loaded from the similar items endpoint and we should refetch
    if (item.itemId && item.index !== 9999 && item.lotsListed) {
        const loaded = getLoadTimeForItemSummary(state, itemId);
        const time = Date.now();
        const diff = time - loaded;
        const catalogDone = item.catalogStatus === AuctionStatus.Done;
        if (diff < ITEM_SUMMARY_CACHE_TIME || catalogDone) {
            return false;
        }
    }
    const loading = getIsItemSummaryLoading(state, itemId);
    return !loading;
};

export const whichItemSummariesNeeded = (state: GlobalState, itemIds: number[]): number[] =>
    // Added a filter to ensure we don't request item card models for items with the itemId 0
    itemIds.filter((itemId) => Boolean(itemId) && shouldFetchItem(state, itemId));
