import { AppDispatch, AppGetState } from '@/redux/store';
import { getAuthToken } from '@/redux/modules/account/user/user.selectors';
import { getDeployment } from './config';
import { IntlShape } from '@liveauctioneers/hammer-ui-core/intl';
import { postItemRemovalResponse } from '@/redux/api/itemRemoval';
import { toast } from '@liveauctioneers/hammer-ui-core/toast';

/* Action Types */
const SUBMIT_ITEM_REMOVAL_RESPONSE_FAIL = 'SUBMIT_ITEM_REMOVAL_RESPONSE_FAIL';
const SUBMIT_ITEM_REMOVAL_RESPONSE_REQUEST = 'SUBMIT_ITEM_REMOVAL_RESPONSE_REQUEST';
const SUBMIT_ITEM_REMOVAL_RESPONSE_SUCCESS = 'SUBMIT_ITEM_REMOVAL_RESPONSE_SUCCESS';

// reducer
export type ItemRemovalResponseSlice = {
    error: boolean;
    errorMessage: string;
    submitted: boolean;
    success: boolean;
};

export const defaultItemRemovalResponseSlice: ItemRemovalResponseSlice = {
    error: false,
    errorMessage: '',
    submitted: false,
    success: false,
};

export default function reducer(
    state: ItemRemovalResponseSlice = defaultItemRemovalResponseSlice,
    action: any = {}
): ItemRemovalResponseSlice {
    switch (action.type) {
        case SUBMIT_ITEM_REMOVAL_RESPONSE_FAIL:
            return {
                ...state,
                error: true,
                errorMessage: action.payload,
                submitted: false,
                success: false,
            };
        case SUBMIT_ITEM_REMOVAL_RESPONSE_REQUEST:
            return {
                ...state,
                error: false,
                errorMessage: '',
                submitted: true,
                success: false,
            };
        case SUBMIT_ITEM_REMOVAL_RESPONSE_SUCCESS:
            return {
                ...state,
                error: false, // TODO: remove
                errorMessage: '', // TODO: remove
                submitted: false,
                success: true,
            };
        default:
            return state;
    }
}

/* SELECTORS */

/* ACTION CREATORS */
export const sumbitAcceptItemRemovalResponse =
    (
        action: 'accept' | 'decline',
        lotId: string,
        verificationCode: string,
        timestamp: string,
        formatMessage: IntlShape['formatMessage']
    ) =>
    async (dispatch: AppDispatch, getState: AppGetState) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);
            dispatch({
                type: SUBMIT_ITEM_REMOVAL_RESPONSE_REQUEST,
            });
            const response = await postItemRemovalResponse({
                authToken,
                deployment,
                lotId,
                response: action === 'accept' ? 'agree' : 'no', // 'agree' || 'no'
                timestamp,
                verificationCode,
            });
            dispatch({
                type: SUBMIT_ITEM_REMOVAL_RESPONSE_SUCCESS,
            });
            toast(
                'success',
                {
                    description: response.message,
                    title: formatMessage({
                        id: 'toastMessages.titles.success',
                    }),
                },
                { autoClose: false }
            );
        } catch (error) {
            dispatch({
                error: true,
                payload: error,
                type: SUBMIT_ITEM_REMOVAL_RESPONSE_FAIL,
            });
        }
    };
