import { createSelector } from '@reduxjs/toolkit';
import { defaultSavedItemsSlice, SavedItemStatus } from '@/redux/modules/item/saved/savedItems.types';
import { getItemSummaries } from '@/redux/modules/item/summary/itemSummary.selectors';
import { GlobalState } from '@/redux/store';
import uniq from 'lodash/uniq';

const stateSelector = (state: GlobalState) => state;
const savedItemsSlice = createSelector(stateSelector, (state) => state.savedItems);

const idSelector = (_: GlobalState, id: number) => id;

const savedItemsSelector = createSelector(savedItemsSlice, (state) => state?.savedItems);

export const getSavedItem = createSelector([savedItemsSelector, idSelector], (savedItems, id) => savedItems[id]);
export const getSavedItemsLoading = createSelector(savedItemsSlice, (state) => state.loading);
export const getSavedItems = createSelector(savedItemsSlice, (state) => state.ids);
export const getSavedItemsCount = createSelector(
    savedItemsSelector,
    (savedItems) => Object.keys(savedItems).filter((item) => savedItems[item].status === SavedItemStatus.Saved).length
);
export const getSavedItemsPagination = createSelector(savedItemsSlice, (state) => ({
    ...defaultSavedItemsSlice.pagination,
    ...state.pagination,
}));

// return a unique list of catalog ids from the loaded saved items
export const getSavedItemCatalogIds = createSelector(
    (state: GlobalState) => {
        const itemIds = getSavedItems(state);
        return getItemSummaries(state, itemIds);
    },
    (items) => uniq(items.map((item) => item.catalogId))
);

export const getSavedItemsItemIds = createSelector(
    (state: GlobalState) => {
        const itemIds = getSavedItems(state);
        return getItemSummaries(state, itemIds);
    },
    (items) => uniq(items.map((item) => item.itemId))
);

export const isItemSaved = createSelector(getSavedItem, (savedItem) => savedItem?.status === SavedItemStatus.Saved);
