import { ActionWithPayload } from '@/types/redux';
import { AppDispatch, AppGetState, GlobalState } from '@/redux/store';
import { combineActions, handleActions } from 'redux-actions';
import { createSelector, Reducer } from '@reduxjs/toolkit';
import { getAuthToken } from '@/redux/modules/account/user/user.selectors';
import { getDeployment } from './config';
import { getFollowSellerAnalytics, getUnfollowSellerAnalytics } from './analytics';
import { LOAD_BIDDER_DATA_SUCCESS } from '@/redux/modules/account/user/user.types';
import { LOG_OUT_BIDDER } from '@/redux/modules/account/logout/logout.actions';
import { LOGIN_SUCCESS } from '@/redux/modules/account/login/login.types';
import { PaginationFilter } from '@liveauctioneers/caterwaul-components/types/PaginationFilter';
import { sellerSortOrders } from '@/enums';
import api from '@/redux/api/seller';
import difference from 'lodash/difference';
import union from 'lodash/union';

/* Action Types */
export const FOLLOW_SELLER_FAIL = 'la/user/followSeller/FAIL';
export const FOLLOW_SELLER_REQUEST = 'la/user/followSeller/REQUEST';
export const FOLLOW_SELLER_SUCCESS = 'la/user/followSeller/SUCCESS';

export const UNFOLLOW_SELLER_FAIL = 'la/user/unfollowSeller/FAIL';
export const UNFOLLOW_SELLER_REQUEST = 'la/user/unfollowSeller/REQUEST';
export const UNFOLLOW_SELLER_SUCCESS = 'la/user/unfollowSeller/SUCCESS';

export const FOLLOW_SELLER_FILTER_CHANGE = 'FOLLOW_SELLER_FILTER_CHANGE';
export const FOLLOW_SELLER_FILTER_CHANGE_FAIL = 'FOLLOW_SELLER_FILTER_CHANGE_FAIL';

// reducer
export const defaultFollowedSellerSlice: FollowedSellerSlice = {
    followedSellerFilters: {
        keyword: '',
        page: 1,
        pageSize: 24,
        sort: sellerSortOrders.NAME_LOWEST_FIRST,
    },
    followedSellerIds: [],
    loading: false,
    success: false,
};

export type FollowedSellerSlice = {
    followedSellerFilters: PaginationFilter;
    followedSellerIds: number[];
    loading: boolean;
    success: boolean;
};

export const followSeller: Reducer<FollowedSellerSlice> = handleActions(
    {
        [FOLLOW_SELLER_FILTER_CHANGE]: (state: FollowedSellerSlice, action: ActionWithPayload<any>) => {
            return {
                ...state,
                followedSellerFilters: {
                    ...state.followedSellerFilters,
                    ...action.payload,
                },
                loading: false,
                success: false,
            };
        },
        [FOLLOW_SELLER_SUCCESS]: (state: FollowedSellerSlice, action: ActionWithPayload<any>) => {
            return {
                ...state,
                followedSellerIds: union(state.followedSellerIds, [action.payload]),
                loading: false,
                success: true,
            };
        },
        [LOG_OUT_BIDDER]: () => {
            return defaultFollowedSellerSlice;
        },
        [combineActions(LOAD_BIDDER_DATA_SUCCESS, LOGIN_SUCCESS)]: (
            state: FollowedSellerSlice,
            action: ActionWithPayload<any>
        ) => {
            return {
                ...state,
                followedSellerIds: [...action.payload.followedSellers],
                loading: false,
                success: false,
            };
        },
        [combineActions(FOLLOW_SELLER_REQUEST, UNFOLLOW_SELLER_REQUEST)]: (state: FollowedSellerSlice) => {
            return {
                ...state,
                loading: true,
                success: false,
            };
        },
        [UNFOLLOW_SELLER_SUCCESS]: (state: FollowedSellerSlice, action: ActionWithPayload<any>) => {
            return {
                ...state,
                followedSellerIds: difference(state.followedSellerIds, [action.payload]),
                loading: false,
                success: true,
            };
        },
    },
    defaultFollowedSellerSlice
);

/* SELECTORS */
const stateSelector = (state: GlobalState) => state;
export const followSellerSelector = createSelector(stateSelector, (state) => state.followSeller);

const idSelector = (_: GlobalState, id: number) => id;

export const getFollowedSellerIds = createSelector(followSellerSelector, (state: FollowedSellerSlice) => [
    ...state.followedSellerIds,
]);

export const getFollowedSellerFilters = createSelector(
    followSellerSelector,
    (state: FollowedSellerSlice) => state.followedSellerFilters
);

export const getFollowedSellerLoading = createSelector(followSellerSelector, ({ loading }) => loading);

export const getFollowedSellerSuccess = createSelector(followSellerSelector, ({ success }) => success);

// TODO: update endpoint to return total followed sellers
export const getTotalFollowedSellerCount = createSelector(
    followSellerSelector,
    (state: FollowedSellerSlice) => state.followedSellerIds.length
);

export const isSellerFollowed = createSelector([getFollowedSellerIds, idSelector], (followedSellerIds, id) =>
    followedSellerIds.includes(id)
);

/* ACTION CREATORS */
export const onFollowSellerFilterChange = (filters: PaginationFilter) => async (dispatch: AppDispatch) => {
    try {
        dispatch({
            payload: filters,
            type: FOLLOW_SELLER_FILTER_CHANGE,
        });
    } catch (error) {
        dispatch({
            error: true,
            payload: error.message,
            type: FOLLOW_SELLER_FILTER_CHANGE_FAIL,
        });
    }
};

export const submitFollowSeller = (sellerId: number) => async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        dispatch({
            type: FOLLOW_SELLER_REQUEST,
        });

        await api.postFollow({ authToken, deployment, sellerId });
        return dispatch({
            meta: getFollowSellerAnalytics(state, sellerId),
            payload: sellerId,
            type: FOLLOW_SELLER_SUCCESS,
        });
    } catch (error) {
        return dispatch({
            error: true,
            payload: error,
            type: FOLLOW_SELLER_FAIL,
        });
    }
};

export const submitUnfollowSeller = (sellerId: number) => async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        dispatch({
            type: UNFOLLOW_SELLER_REQUEST,
        });
        await api.postUnfollow({ authToken, deployment, sellerId });
        dispatch({
            meta: getUnfollowSellerAnalytics(state, sellerId),
            payload: sellerId,
            type: UNFOLLOW_SELLER_SUCCESS,
        });
    } catch (error) {
        dispatch({
            error: true,
            payload: error,
            type: UNFOLLOW_SELLER_FAIL,
        });
    }
};
