import { AppDispatch, GlobalState } from '@/redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { Locales } from './intl.types';

/* Action Types */
const UPDATE = '@@intl/UPDATE';

export interface IntlSlice {
    readonly locale: Locales;
}

/* reducer */
export const defaultIntlSlice: IntlSlice = {
    locale: Locales.en,
};

export default function reducer(state: IntlSlice = defaultIntlSlice, action: any = {}): IntlSlice {
    switch (action.type) {
        case UPDATE:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state: GlobalState) => state;
const intlSlice = createSelector(stateSelector, (state) => state.intl);

export const getLocale = createSelector(intlSlice, (intl) => intl.locale || Locales.en);

/* ACTION CREATORS */
const updateIntl = ({ locale }: IntlSlice) => ({
    payload: { locale },
    type: UPDATE,
});

export const switchLocale = (locale: IntlSlice['locale']) => async (dispatch: AppDispatch) => {
    try {
        dispatch(
            updateIntl({
                locale: locale,
            })
        );
    } catch (error) {}
};
