import { createAsyncThunk } from '@/redux/createAsyncThunk';
import { fetchBidHistoryIfNeeded } from '../bidHistory';
import { fetchCatalogDetailsIfNeeded } from '../catalog/detail/catalogDetail.actions';
import { fetchCatalogItemSummaries } from '../catalog/items/catalogItems.actions';
import { fetchCatalogsIfNeeded } from '../catalog/catalogs/catalog.actions';
import { fetchCategoryRelatedSearches } from '../categoryRelatedSearches';
import { fetchListingAgentsIfNeeded } from '../listingAgent';
import { fetchSellerFollowerCountIfNeeded } from '../sellerFollowerCount';
import { fetchSellerRatingSummaryByIdIfNeeded } from '../sellerRatings';
import { fetchSellersDetailsIfNeeded } from '../sellerDetail';
import { fetchSimilarSoldItemsIfNeeded } from '../similarSoldItems';
import { getFacetIds } from '@/utils/ItemPageUtils';
import { getItemDetailAgentId } from '../itemDetail';
import { getItemFacets } from '../itemFacets';
import {
    getItemIndex,
    getItemModelCatalogId,
    getItemModelSellerId,
    getItemModelTitle,
} from '../item/summary/itemSummary.selectors';
import { loadRelatedGeoAreaByLocation } from '../geoArea/geoArea';
import TEST_HOUSES from '@/utils/testHouses';

export const fetchDataForEndedItemPage = createAsyncThunk<
    Promise<unknown>,
    {
        itemId: number;
    }
>('la/domain/itemPage/fetchDataForEndedItemPage', async ({ itemId }, { dispatch, getState }) => {
    const state = getState();
    const catalogId = getItemModelCatalogId(state, itemId);
    const sellerId = getItemModelSellerId(state, itemId);
    const index = getItemIndex(state, itemId);
    const title = getItemModelTitle(state, itemId);
    const agentId = getItemDetailAgentId(state, itemId);
    const facets = getItemFacets(state, itemId);
    const categoryIds = getFacetIds(facets);

    // we need to load the previous item plus the next 2 items for the item page
    const startingIndexForCatalogCarousel = index === 0 ? 0 : index - 1;

    const query: any = {
        index: startingIndexForCatalogCarousel,
        pageSize: 4,
    };
    if (TEST_HOUSES.includes(sellerId)) {
        query.preview = true;
    }

    const promises: Promise<unknown>[] = [
        dispatch(fetchBidHistoryIfNeeded(itemId)),
        dispatch(fetchCatalogDetailsIfNeeded([catalogId])),
        dispatch(fetchCatalogItemSummaries({ catalogId, query })),
        dispatch(fetchCatalogsIfNeeded({ catalogIds: [catalogId] })),
        dispatch(fetchListingAgentsIfNeeded([agentId])),
        dispatch(fetchSellerFollowerCountIfNeeded([sellerId])),
        dispatch(fetchSellerRatingSummaryByIdIfNeeded(sellerId)),
        dispatch(fetchSellersDetailsIfNeeded([sellerId])),
        dispatch(
            fetchSimilarSoldItemsIfNeeded({
                categoryIds,
                itemId,
                sellerId,
                title,
            })
        ),
        dispatch(loadRelatedGeoAreaByLocation(catalogId)),
        dispatch(fetchCategoryRelatedSearches([itemId])),
    ];

    return Promise.all(promises);
});
