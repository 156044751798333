import { ItemSummary } from '@/types/item/ItemSummary';
import ms from 'ms';

export type ItemSummarySlice = {
    byId: {
        [itemId: number]: ItemSummary;
    };
    loaded: {
        [itemId: number]: number;
    };
    loading: number[];
};

export const defaultItemSummarySlice: ItemSummarySlice = {
    byId: {},
    loaded: {},
    loading: [],
};

export type NextAndPreviousItemResponse = {
    next: number;
    previous: number;
};

export const ITEM_SUMMARY_CACHE_TIME = ms('5m');
export const ITEM_SUMMARY_FETCH_CHUNK_SIZE = 400;

export type FetchItemSummariesPayload = {
    force?: boolean;
    identifier: string;
    itemIds: number[];
    // Used on the console to ensure the bidder's bidding status is correct on page load
    liveStateFetch?: boolean;
    similarItems?: boolean;
};

export type FetchItemSummaryPayload = {
    force?: boolean;
    identifier: string;
    itemId: number;
    // Used on the console to ensure the bidder's bidding status is correct on page load
    liveStateFetch?: boolean;
};
