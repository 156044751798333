import { ACN } from '@/types/ACN';
import ms from 'ms';

export const HOME_CACHE_TIME = ms('5m');
export const MAX_TODAY_AUCTIONS = 20;

export type FeaturedAuctioneer = {
    destinationURL: string;
    endTime: string;
    featuredAuctioneerId: number;
    houseId: number;
    houseName: string;
    imageURL: string;
    positionColumn: number;
    startTime: string;
};

export type HomeSlice = {
    acn: ACN;
    featuredAuctioneers: FeaturedAuctioneer[];
    featuredCatalogs: number[];
    loaded: number;
    loading: boolean;
    trendingItems: number[];
    upcomingCatalogs: number[];
};

export const defaultHomeSlice: HomeSlice = {
    acn: {
        auctionPreviews: [],
        auctionResults: [],
        beInformed: { header: { imageUrl: '', link: '', title: '' }, sub: [] },
        topNews: [],
    },
    featuredAuctioneers: [],
    featuredCatalogs: [],
    loaded: 0,
    loading: false,
    trendingItems: [],
    upcomingCatalogs: [],
};
