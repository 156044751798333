import { ApiHosts, handleResponseWithNon200Errors, makeGet } from '@/redux/api/helpers';
import type { FetchUserPlacedBidItemSummariesResponse, UserBidPlacedItemsParams } from './userBidPlacedItems.types';

type FetchUserPlacedBidItemSummariesParams = {
    authToken: string;
    bidderId: number;
    deployment: string;
    params: UserBidPlacedItemsParams;
};

/**
 * Fetches item summaries for items that the logged-in user has placed bids on
 * @function fetchUserPlacedBidItemSummaries
 * @category ItemSummary API
 * @param {FetchUserPlacedBidItemSummariesParams}
 * @see https://catalog-bidder-content-DEPLOYMENT.liveauctioneers.com/content/bidder/BIDDER_ID/my-bids
 * @see https://github.com/LIVEauctioneers/catalog-bidder-content
 */
export const fetchUserPlacedBidItemSummaries = ({
    authToken,
    bidderId,
    deployment,
    params,
}: FetchUserPlacedBidItemSummariesParams) =>
    new Promise<FetchUserPlacedBidItemSummariesResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: `bidder/${bidderId}/my-bids`,
            authToken,
            deployment,
            path: ApiHosts.ItemModel,
        });

        request.query(params);
        request.end((err, response) => handleResponseWithNon200Errors({ err, reject, resolve, response }));
    });
