import { createSlice } from '@reduxjs/toolkit';
import { defaultItemCarouselState } from './itemCarousel.types';
import { loadItemCarousels } from './itemCarousel.actions';

const itemCarouselSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(loadItemCarousels.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(loadItemCarousels.fulfilled, (state, { payload }) => {
            state.byId[payload.itemId] = payload.carousels.map(({ items, title, viewMoreUrl }) => ({
                itemIds: items.map((item) => item.itemId),
                title,
                viewMoreUrl,
            }));
            state.loading = false;
        });

        builder.addCase(loadItemCarousels.rejected, (state) => {
            state.loading = false;
        });
    },
    initialState: defaultItemCarouselState,
    name: 'itemCarousel',
    reducers: {},
});

export const { reducer: itemCarouselReducer } = itemCarouselSlice;
