import { createSelector } from '@reduxjs/toolkit';
import { defaultHomeSlice, HOME_CACHE_TIME } from '@/redux/modules/home/home.types';
import { GlobalState } from '@/redux/store';
import isEmpty from 'lodash/isEmpty';

const stateSelector = (state: GlobalState) => state;
const homeSlice = createSelector(stateSelector, (state) => state.home);

export const getFeaturedCatalogIds = createSelector(
    homeSlice,
    ({ featuredCatalogs }) => featuredCatalogs || defaultHomeSlice.featuredCatalogs
);
export const getTrendingItems = createSelector(
    homeSlice,
    ({ trendingItems }) => trendingItems || defaultHomeSlice.trendingItems
);
export const getUpcomingCatalogIds = createSelector(
    homeSlice,
    ({ upcomingCatalogs }) => upcomingCatalogs || defaultHomeSlice.upcomingCatalogs
);
export const getFeaturedAuctioneers = createSelector(
    homeSlice,
    ({ featuredAuctioneers }) => featuredAuctioneers || defaultHomeSlice.featuredAuctioneers
);

export const getFeaturedAuctioneersExist = createSelector(
    homeSlice,
    ({ featuredAuctioneers }) => Boolean(featuredAuctioneers.length) || false
);

export const getACNData = createSelector(homeSlice, ({ acn }) => acn || defaultHomeSlice.acn);
export const getACNTopNews = createSelector(getACNData, ({ topNews }) => topNews);
export const getACNAuctionPreviews = createSelector(getACNData, ({ auctionPreviews }) => auctionPreviews);
export const getACNAuctionResults = createSelector(getACNData, ({ auctionResults }) => auctionResults);
export const getACNBeInformed = createSelector(getACNData, ({ beInformed }) => beInformed);

export const shouldFetchHomeData = createSelector(homeSlice, ({ featuredCatalogs, loaded, loading }) => {
    if (loading) {
        return false;
    }

    if (loaded || isEmpty(featuredCatalogs)) {
        const time = Date.now();
        const diff = time - loaded;
        if (diff < HOME_CACHE_TIME) {
            return false;
        }
    }
    return true;
});
