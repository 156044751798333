import { createSelector } from '@reduxjs/toolkit';
import { GlobalState } from '@/redux/store';
import { KameleoonExperimentIds } from '@/hooks/kameleoon/kameleoonExperiments';

const getSlice = ({ kameleoonExperiments }: GlobalState) => kameleoonExperiments;

const byFlagSelector = (_, flag: KameleoonExperimentIds) => flag;

export const getUserSelectedVariant = createSelector(
    [getSlice, byFlagSelector],
    ({ userSelectedVariants }, flag) => userSelectedVariants[flag]
);

export const getActiveVariants = createSelector(getSlice, ({ activeVariants }) => activeVariants);

export const getUserSelectedVariants = createSelector(getSlice, ({ userSelectedVariants }) => userSelectedVariants);

export const getUserChangedVariant = createSelector(getSlice, ({ userChangedVariant }) => userChangedVariant);

export const getVisitorCode = createSelector(getSlice, ({ visitorCode }) => visitorCode);
