import { AppDispatch, GlobalState } from '@/redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { HealthCheckData } from '@/types/HealthCheckData';
import api from '@/redux/api/healthcheck';

/* Action Types */
export const LOAD_HEALTH_CHECK_DATA_FAIL = 'la/domain/healthcheck/LOAD_FAIL';
export const LOAD_HEALTH_CHECK_DATA_REQUEST = 'la/domain/healthcheck/LOAD_REQUEST';
export const LOAD_HEALTH_CHECK_DATA_SUCCESS = 'la/domain/healthcheck/LOAD_SUCCESS';

export type HealthCheckState = {
    data: HealthCheckData;
    loading: boolean;
};

// reducer
export const DEFAULT_STATE: HealthCheckState = {
    data: {
        application: {
            build_number: '',
            deployment: '',
            name: '',
        },
        server: {
            architecture: '',
            cpus: [],
            memory: {
                total_available: '',
                total_free: '',
            },
            node: '',
            platform: '',
            status: '',
            uptime: '',
            utilization: {
                'fifteen minute average': 0,
                'five minute average': 0,
                'one minute average': 0,
            },
        },
    },
    loading: false,
};

export default function reducer(state: HealthCheckState = DEFAULT_STATE, action: any = {}): HealthCheckState {
    switch (action.type) {
        case LOAD_HEALTH_CHECK_DATA_FAIL:
            return {
                ...state,
                loading: false,
            };
        case LOAD_HEALTH_CHECK_DATA_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case LOAD_HEALTH_CHECK_DATA_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false,
            };
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state: GlobalState) => state;
const healthcheckSlice = createSelector(stateSelector, (state) => state.healthcheck);

export const getHealthCheckData = createSelector(healthcheckSlice, (state) => state.data);
export const getHealthCheckLoading = createSelector(healthcheckSlice, (state) => state.loading);

/* ACTION CREATORS */
const loadHealthCheckDataFail = (error: unknown) => ({
    error: true,
    payload: error,
    type: LOAD_HEALTH_CHECK_DATA_FAIL,
});

const loadHealthCheckDataRequest = () => ({
    type: LOAD_HEALTH_CHECK_DATA_REQUEST,
});

const loadHealthCheckDataSuccess = (HealthCheckData: HealthCheckData) => ({
    payload: HealthCheckData,
    type: LOAD_HEALTH_CHECK_DATA_SUCCESS,
});

export const fetchHealthCheckData = () => {
    return (dispatch: AppDispatch) => {
        dispatch(loadHealthCheckDataRequest());
        return api
            .fetchHealthCheckData()
            .then((response) => {
                dispatch(loadHealthCheckDataSuccess(response));
            })
            .catch((error) => {
                dispatch(loadHealthCheckDataFail(error));
            });
    };
};
