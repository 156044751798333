import { AppDispatch, AppGetState, GlobalState } from '@/redux/store';
import {
    fetchAllGeoAreas,
    fetchGeoArea,
    fetchRelatedGeoAreaByCityStateCountry,
    fetchRelatedGeoAreaCountries,
    fetchRelatedGeoAreas,
} from '@/redux/api/geoArea/geoArea';
import { GeoArea, RelatedGeoArea } from '@/types/GeoArea';
import {
    GeoAreaReducerAction,
    LoadAllGeoAreasFailureAction,
    LoadAllGeoAreasRequestAction,
    LoadAllGeoAreasSuccessAction,
    LoadGeoAreaFailureAction,
    LoadGeoAreaRequestAction,
    LoadGeoAreaSuccessAction,
    LoadRelatedGeoAreaByLocationFailureAction,
    LoadRelatedGeoAreaByLocationRequestAction,
    LoadRelatedGeoAreaByLocationSuccessAction,
    LoadRelatedGeoAreaFailureAction,
    LoadRelatedGeoAreaSuccessAction,
} from '@/redux/modules/geoArea/geoArea.types';
import { getDeployment } from '@/redux/modules/config';
import {
    LOAD_ALL_GEO_AREAS_FAILURE,
    LOAD_ALL_GEO_AREAS_REQUEST,
    LOAD_ALL_GEO_AREAS_SUCCESS,
    LOAD_GEO_AREA_FAILURE,
    LOAD_GEO_AREA_REQUEST,
    LOAD_GEO_AREA_SUCCESS,
    LOAD_RELATED_GEO_AREA_BY_LOCATION_FAILURE,
    LOAD_RELATED_GEO_AREA_BY_LOCATION_REQUEST,
    LOAD_RELATED_GEO_AREA_BY_LOCATION_SUCCESS,
    LOAD_RELATED_GEO_AREA_FAILURE,
    LOAD_RELATED_GEO_AREA_SUCCESS,
} from '@/redux/modules/actions/geoArea/geoArea';
import ms from 'ms';

export type GeoAreaState = {
    addressBasedRelatedGeoArea: RelatedGeoArea | null;
    byId: { [id: number]: GeoArea };
    byRelatedGeoAreasId: { [id: number]: RelatedGeoArea[] };
    loaded: { [id: number]: number };
    loading: number[];
};

export const defaultGeoAreaState: GeoAreaState = {
    addressBasedRelatedGeoArea: null,
    byId: {},
    byRelatedGeoAreasId: {},
    loaded: {},
    loading: [],
};

const getLoadRequestState = (state: GeoAreaState, action: LoadGeoAreaRequestAction): GeoAreaState => {
    const geoAreaId: number = action.payload;
    if (state.loading.includes(geoAreaId)) {
        return state;
    }
    return {
        ...state,
        loading: [...state.loading, geoAreaId],
    };
};

const getSuccessState = (state: GeoAreaState, { meta, payload }: LoadGeoAreaSuccessAction): GeoAreaState => {
    const cleanedLoading = state.loading.filter((id) => id !== payload.geoAreaId);
    return {
        ...state,
        byId: {
            ...state.byId,
            [payload.geoAreaId]: payload,
        },
        loaded: {
            ...state.loaded,
            [payload.geoAreaId]: meta.actionTime,
        },
        loading: cleanedLoading,
    };
};

const getFailureState = (state: GeoAreaState, { meta }: LoadGeoAreaFailureAction): GeoAreaState => {
    const cleanedLoading = state.loading.filter((id) => id !== meta.geoAreaId);
    return {
        ...state,
        loading: cleanedLoading,
    };
};

const getAllAreasSuccessState = (
    state: GeoAreaState,
    { meta, payload }: LoadAllGeoAreasSuccessAction
): GeoAreaState => {
    const byId: { [id: number]: GeoArea } = {};
    const loaded: { [id: number]: number } = {};
    payload.forEach((geoArea) => {
        byId[geoArea.geoAreaId] = geoArea;
        loaded[geoArea.geoAreaId] = meta.actionTime;
    });
    return {
        ...state,
        byId,
        loaded,
    };
};

const getRelatedSuccessState = (state: GeoAreaState, { payload }: LoadRelatedGeoAreaSuccessAction): GeoAreaState => {
    const cleanedLoading = state.loading.filter((id) => id !== payload.geoAreaId);
    return {
        ...state,
        byRelatedGeoAreasId: {
            ...state.byRelatedGeoAreasId,
            [payload.geoAreaId]: payload.relatedGeoAreas,
        },
        loading: cleanedLoading,
    };
};

const getRelatedFailureState = (state: GeoAreaState, { meta }: LoadRelatedGeoAreaFailureAction): GeoAreaState => {
    const cleanedLoading = state.loading.filter((id) => id !== meta?.geoAreaId);
    return {
        ...state,
        loading: cleanedLoading,
    };
};

const getRelatedGeoAreaByLocationSuccessState = (
    state: GeoAreaState,
    { payload }: LoadRelatedGeoAreaByLocationSuccessAction
): GeoAreaState => ({
    ...state,
    addressBasedRelatedGeoArea: payload,
});

const reducer = (state: GeoAreaState, action: GeoAreaReducerAction): GeoAreaState => {
    const populatedState = Boolean(state) ? state : defaultGeoAreaState;
    switch (action.type) {
        case LOAD_GEO_AREA_REQUEST:
            return getLoadRequestState(populatedState, action as LoadGeoAreaRequestAction);
        case LOAD_GEO_AREA_SUCCESS:
            return getSuccessState(populatedState, action as LoadGeoAreaSuccessAction);
        case LOAD_GEO_AREA_FAILURE:
            return getFailureState(populatedState, action as LoadGeoAreaFailureAction);
        case LOAD_RELATED_GEO_AREA_SUCCESS:
            return getRelatedSuccessState(populatedState, action as LoadRelatedGeoAreaSuccessAction);
        case LOAD_RELATED_GEO_AREA_FAILURE:
            return getRelatedFailureState(populatedState, action as LoadRelatedGeoAreaFailureAction);
        case LOAD_ALL_GEO_AREAS_SUCCESS:
            return getAllAreasSuccessState(populatedState, action as LoadAllGeoAreasSuccessAction);
        case LOAD_RELATED_GEO_AREA_BY_LOCATION_SUCCESS:
            return getRelatedGeoAreaByLocationSuccessState(
                populatedState,
                action as LoadRelatedGeoAreaByLocationSuccessAction
            );
        case LOAD_ALL_GEO_AREAS_REQUEST:
        case LOAD_ALL_GEO_AREAS_FAILURE:
        default:
            return populatedState;
    }
};

export default reducer;

export const GEO_AREA_REDUX_STORE_TIME = ms('1h');

const shouldFetchGeoArea = (state: GlobalState, id: number): boolean => {
    if ((!id && id !== 0) || id < 0) {
        return false;
    }
    const { geoArea } = state;
    if (geoArea.byId[id]) {
        const loaded = geoArea.loaded[id];
        const now = Date.now();
        const diff = now - loaded;
        if (diff < GEO_AREA_REDUX_STORE_TIME) {
            return false;
        }
    }
    return !geoArea.loading.includes(id);
};

const loadGeoArea =
    (geoAreaId: number) =>
    async (dispatch: AppDispatch, getState: AppGetState): Promise<void> => {
        if (!Boolean(geoAreaId)) {
            return;
        }
        try {
            const requestAction: LoadGeoAreaRequestAction = {
                error: false,
                payload: geoAreaId,
                type: LOAD_GEO_AREA_REQUEST,
            };
            dispatch(requestAction);

            const state: GlobalState = getState();
            const deployment = getDeployment(state);
            const response = await fetchGeoArea({ deployment, geoAreaId });
            const successAction: LoadGeoAreaSuccessAction = {
                error: false,
                meta: { actionTime: Date.now() },
                payload: response.data,
                type: LOAD_GEO_AREA_SUCCESS,
            };
            dispatch(successAction);
        } catch (error) {
            const failureAction: LoadGeoAreaFailureAction = {
                error: true,
                meta: { geoAreaId },
                payload: error,
                type: LOAD_GEO_AREA_FAILURE,
            };
            dispatch(failureAction);
        }
    };

const loadRelatedGeoAreas =
    (geoAreaId: number) =>
    async (dispatch: AppDispatch, getState: AppGetState): Promise<void> => {
        try {
            const state: GlobalState = getState();
            const deployment = getDeployment(state);
            let response;
            if (Boolean(geoAreaId !== 0)) {
                response = await fetchRelatedGeoAreas({
                    deployment,
                    geoAreaId,
                });
            } else {
                response = await fetchRelatedGeoAreaCountries({ deployment });
            }
            const successAction: LoadRelatedGeoAreaSuccessAction = {
                error: false,
                payload: {
                    geoAreaId: geoAreaId,
                    relatedGeoAreas: response.data,
                },
                type: LOAD_RELATED_GEO_AREA_SUCCESS,
            };
            dispatch(successAction);
        } catch (error) {
            const failureAction: LoadRelatedGeoAreaFailureAction = {
                error: true,
                meta: null,
                payload: error,
                type: LOAD_RELATED_GEO_AREA_FAILURE,
            };
            dispatch(failureAction);
        }
    };

/**
 * @param geoAreaId desired geoArea id
 * @return Void
 * If the geoArea is on the store and not outdated, that will be returned,
 * otherwise an API request will be made
 * */
export const fetchGeoAreaIfNeeded =
    (geoAreaId: number) =>
    async (dispatch: AppDispatch, getState: AppGetState): Promise<any> => {
        let dispatchPromises: Promise<void>[] = [];
        if (shouldFetchGeoArea(getState(), geoAreaId)) {
            dispatchPromises.push(dispatch(loadRelatedGeoAreas(geoAreaId)));
            dispatchPromises.push(dispatch(loadGeoArea(geoAreaId)));
        }
        return Promise.all(dispatchPromises);
    };

/**
 * @return Void
 * Makes a network request to load all available GeoAreas
 */
export const loadAllGeoAreas =
    () =>
    async (dispatch: AppDispatch, getState: AppGetState): Promise<void> => {
        try {
            const requestAction: LoadAllGeoAreasRequestAction = {
                type: LOAD_ALL_GEO_AREAS_REQUEST,
            };
            dispatch(requestAction);

            const state = getState();
            const deployment = getDeployment(state);
            const response = await fetchAllGeoAreas(deployment);
            const successAction: LoadAllGeoAreasSuccessAction = {
                error: false,
                meta: { actionTime: Date.now() },
                payload: response.data,
                type: LOAD_ALL_GEO_AREAS_SUCCESS,
            };
            dispatch(successAction);
        } catch (error) {
            const failureAction: LoadAllGeoAreasFailureAction = {
                error: true,
                payload: error,
                type: LOAD_ALL_GEO_AREAS_FAILURE,
            };
            dispatch(failureAction);
        }
    };

/**
 * @return Void
 * Makes a network request to load related geo area based on city, state, and country
 */
export const loadRelatedGeoAreaByLocation =
    (catalogId: number) =>
    async (dispatch: AppDispatch, getState: AppGetState): Promise<void> => {
        try {
            const requestAction: LoadRelatedGeoAreaByLocationRequestAction = {
                type: LOAD_RELATED_GEO_AREA_BY_LOCATION_REQUEST,
            };
            dispatch(requestAction);

            const deployment = getDeployment(getState());
            const { data } = await fetchRelatedGeoAreaByCityStateCountry({
                catalogId,
                deployment,
            });
            const successAction: LoadRelatedGeoAreaByLocationSuccessAction = {
                error: false,
                payload: data,
                type: LOAD_RELATED_GEO_AREA_BY_LOCATION_SUCCESS,
            };
            dispatch(successAction);
        } catch (error) {
            const failureAction: LoadRelatedGeoAreaByLocationFailureAction = {
                error: true,
                payload: error,
                type: LOAD_RELATED_GEO_AREA_BY_LOCATION_FAILURE,
            };
            dispatch(failureAction);
        }
    };
