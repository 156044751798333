import { ApiHosts, handleResponseOld, makeGet } from '@/redux/api/helpers';
import { FetchItemCarouselsParams, FetchItemCarouselsResponse } from './itemCarousel.types';
import { WithAuthAndDeployment } from '../../commonTypes';

/**
 * Fetch all upcoming catalogs via Get to Item API service
 * @function fetchItemCarousels
 * @category Search API
 * @param {FetchUpcomingCatalogsParams}
 * @see https://api-DEPLOYMENT.liveauctioneers.com/search/similaritemsgroups
 * @see https://github.com/LIVEauctioneers/search-party/blob/c065053845529b5ec34f10486df28399d75b7fa5/internal/handlers/similarItemsHandler.go#L298
 * @see `SimilarItemsGroupsHandler`
 * @see https://liveauctioneers.atlassian.net/browse/SW-964
 */
export const fetchItemCarousels = ({
    authToken,
    deployment,
    ipAddress,
    ...rest
}: WithAuthAndDeployment<FetchItemCarouselsParams>) =>
    new Promise<FetchItemCarouselsResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'similaritemsgroups',
            authToken,
            deployment,
            ipAddress,
            path: ApiHosts.Search,
        });
        const query = { ...rest };
        request.query(query);
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });
