import { AppDispatch, AppGetState, GlobalState } from '@/redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { getDeployment } from './config';
import { ItemFacets } from '@/types/ItemFacets';
import api from '@/redux/api/itemFacets';
import cloneDeep from 'lodash/cloneDeep';
import difference from 'lodash/difference';
import isEmpty from 'lodash/isEmpty';
import ms from 'ms';
import union from 'lodash/union';

/* Action Types */
export const LOAD_ITEM_FACETS_FAIL = 'la/domain/itemFacet/LOAD_FAIL';
export const LOAD_ITEM_FACETS_REQUEST = 'la/domain/itemFacet/LOAD_REQUEST';
export const LOAD_ITEM_FACETS_SUCCESS = 'la/domain/itemFacet/LOAD_SUCCESS';

const REDUX_STORE_TIME = ms('30m');

export type ItemFacetsState = {
    // dictionary of ItemFacets using itemId as key
    byId: { [id: number]: ItemFacets };
    // dictionary of when ItemFacets were loaded, using itemId as key
    loaded: { [id: number]: number };
    // collection of ItemFacets that are still loading
    loading: number[];
};

/* reducer */
export const defaultItemFacetsSlice: ItemFacetsState = {
    byId: {},
    loaded: {},
    loading: [],
};

export default function reducer(state = defaultItemFacetsSlice, action: any = {}): ItemFacetsState {
    let existing;
    let loaded;
    let loading;
    let time;

    switch (action.type) {
        case LOAD_ITEM_FACETS_FAIL:
            return {
                ...state,
                loading: difference(cloneDeep(state.loading), action.meta.itemIds),
            };
        case LOAD_ITEM_FACETS_REQUEST:
            return {
                ...state,
                loading: union(cloneDeep(state.loading), action.payload),
            };
        case LOAD_ITEM_FACETS_SUCCESS:
            existing = cloneDeep(state.byId);
            loaded = cloneDeep(state.loaded);
            loading = cloneDeep(state.loading);
            time = action.meta.actionTime;
            cloneDeep(action.payload).forEach((facets) => {
                const { itemId } = facets;
                delete facets.itemId;
                existing[itemId] = { ...facets };
                loaded[itemId] = time;
                loading = difference(loading, [itemId]);
            });
            return {
                ...state,
                byId: existing,
                loaded,
                loading,
            };
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state: GlobalState) => state;
const itemFacetsSlice = createSelector(stateSelector, (state) => state.itemFacets);

const idSelector = (_: GlobalState, id: number) => id;

const byIdSelector = createSelector(itemFacetsSlice, (state) => state.byId);

const loadedSelector = createSelector(itemFacetsSlice, (state) => state.loaded);

const loadingSelector = createSelector(itemFacetsSlice, (state) => state.loading);

// TODO: we should make this return a default ItemFacets in an OP story
export const getItemFacets = createSelector([byIdSelector, idSelector], (byId, id) => byId[id] || ({} as ItemFacets));

const getLoadTimeForItemFacets = createSelector([loadedSelector, idSelector], (loaded, id) => loaded[id] || 0);

const isItemFacetsLoading = createSelector([loadingSelector, idSelector], (loading, id) => loading.includes(id));

const shouldFetchItemFacets = (state, itemId) => {
    const data = getItemFacets(state, itemId);
    if (!isEmpty(data)) {
        const loaded = getLoadTimeForItemFacets(state, itemId);
        const time = Date.now();
        const diff = time - loaded;
        if (diff < REDUX_STORE_TIME) {
            return false;
        }
    }
    const loading = isItemFacetsLoading(state, itemId);
    return !loading;
};

const whichItemFacetsNeeded = (state, itemIds) =>
    // Added a filter to ensure we dont request item facets for items with the itemId 0
    itemIds.filter((itemId) => Boolean(itemId) && shouldFetchItemFacets(state, itemId));

/* ACTION CREATORS */
const loadItemFacetsFail = (itemIds, error) => ({
    error: true,
    meta: { itemIds },
    payload: error,
    type: LOAD_ITEM_FACETS_FAIL,
});

const loadItemFacetsRequest = (itemIds) => ({
    payload: itemIds,
    type: LOAD_ITEM_FACETS_REQUEST,
});

const loadItemFacetsSuccess = (itemIds, results) => ({
    meta: { actionTime: Date.now(), itemIds },
    payload: results,
    type: LOAD_ITEM_FACETS_SUCCESS,
});

const loadItemFacets = (itemIds) => async (dispatch, getState) => {
    try {
        const state = getState();
        const deployment = getDeployment(state);
        dispatch(loadItemFacetsRequest(itemIds));
        const response = await api.fetchItemFacets({
            deployment,
            ids: itemIds,
        });
        return dispatch(loadItemFacetsSuccess(itemIds, response.data));
    } catch (error) {
        dispatch(loadItemFacetsFail(itemIds, error));
    }
};

export const fetchItemFacetsIfNeeded = (itemIds: number[]) => async (dispatch: AppDispatch, getState: AppGetState) => {
    const neededItems = whichItemFacetsNeeded(getState(), itemIds);
    if (neededItems.length) {
        return dispatch(loadItemFacets(neededItems));
    }
    return Promise.resolve();
};
