import { createAsyncThunk } from '@/redux/createAsyncThunk';
import { fetchBidLimitBalanceIfNeeded } from '../bidLimit';
import { fetchCatalogAnnouncementIfNeeded } from '../catalog/announcement/catalogAnnouncement.actions';
import { fetchCatalogDetailsIfNeeded } from '../catalog/detail/catalogDetail.actions';
import { fetchCatalogItemSummaries } from '../catalog/items/catalogItems.actions';
import { fetchCatalogPresence, fetchLiveCatalogStatusIfNeeded } from '../liveCatalogStatus';
import { fetchCatalogRegistrationsForUserByIdIfNeeded } from '@/redux/modules/catalog/registration/catalogRegistration.actions';
import { fetchCatalogsIfNeeded } from '../catalog/catalogs/catalog.actions';
import { fetchCategoryRelatedSearches } from '../categoryRelatedSearches';
import { fetchItemCarouselsIfNeeded } from '../itemCarousel/itemCarousel.actions';
import { fetchItemShippingEstimateIfNeeded } from '@/redux/modules/shipping/shipping.actions';
import { fetchListingAgentsIfNeeded } from '../listingAgent';
import { fetchSellerFollowerCountIfNeeded } from '../sellerFollowerCount';
import { fetchSellerRatingSummaryByIdIfNeeded } from '../sellerRatings';
import { fetchSellersDetailsIfNeeded } from '../sellerDetail';
import { fetchSellersIfNeeded } from '../seller';
import { getDefaultAddress, isUserLoggedIn } from '@/redux/modules/account/user/user.selectors';
import { getHasRequiredShippingDimensions, getItemDetailAgentId } from '../itemDetail';
import { getItemSummary } from '../item/summary/itemSummary.selectors';
import { loadRelatedGeoAreaByLocation } from '../geoArea/geoArea';
import TEST_HOUSES from '@/utils/testHouses';

export const fetchDataForLiveItemPage = createAsyncThunk<
    Promise<unknown>,
    {
        itemId: number;
    }
>('la/domain/itemPage/fetchDataForLiveItemPage', async ({ itemId }, { dispatch, getState }) => {
    let state = getState();
    const isLoggedIn = isUserLoggedIn(state);
    const { catalogId, index, isPassed, isSold, sellerId, shippingProviderId } = getItemSummary(state, itemId);
    const agentId = getItemDetailAgentId(state, itemId);
    // we need to load the previous item plus the next 20 items for the item page
    const startingIndexForCatalogCarousel = index === 0 ? 0 : index - 1;

    const query: any = {
        index: startingIndexForCatalogCarousel,
        pageSize: 22,
    };
    if (TEST_HOUSES.includes(sellerId)) {
        query.preview = true;
    }

    const promises: Promise<unknown>[] = [
        dispatch(
            fetchCatalogAnnouncementIfNeeded({
                catalogId,
                houseId: sellerId,
            })
        ),
        dispatch(fetchCatalogDetailsIfNeeded([catalogId])),
        dispatch(fetchCatalogItemSummaries({ catalogId, query })),
        dispatch(fetchCatalogPresence(catalogId)),
        dispatch(fetchCatalogsIfNeeded({ catalogIds: [catalogId] })),
        dispatch(fetchItemCarouselsIfNeeded(itemId)),
        dispatch(fetchListingAgentsIfNeeded([agentId])),
        dispatch(fetchSellerFollowerCountIfNeeded([sellerId])),
        dispatch(fetchSellerRatingSummaryByIdIfNeeded(sellerId)),
        dispatch(fetchSellersDetailsIfNeeded([sellerId])),
        dispatch(loadRelatedGeoAreaByLocation(catalogId)),
        dispatch(fetchCategoryRelatedSearches([itemId])),
    ];

    if (isLoggedIn) {
        promises.push(dispatch(fetchBidLimitBalanceIfNeeded({ catalogId, itemId })));
        promises.push(
            dispatch(
                fetchCatalogRegistrationsForUserByIdIfNeeded({
                    catalogIds: [catalogId],
                })
            )
        );

        // need to get seller betas so we can know if we need to submit auto approval
        await dispatch(fetchSellersIfNeeded([sellerId]));
        state = getState();

        const bidderAddress = getDefaultAddress(state);
        const hasRequiredShippingDimensions = getHasRequiredShippingDimensions(state, itemId);
        const isItemClosed = isSold || isPassed;
        if (
            Boolean(bidderAddress) &&
            Boolean(shippingProviderId) &&
            hasRequiredShippingDimensions &&
            Boolean(bidderAddress.postalCode) &&
            !isItemClosed
        ) {
            promises.push(
                dispatch(
                    fetchItemShippingEstimateIfNeeded({
                        countryCode: bidderAddress.countryCode,
                        itemId,
                        postalCode: bidderAddress.postalCode,
                    })
                )
            );
        }
    }

    await Promise.all(promises);
    return dispatch(fetchLiveCatalogStatusIfNeeded(catalogId, sellerId));
});
