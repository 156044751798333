import { createSlice } from '@reduxjs/toolkit';
import { defaultSavedItemsSlice, SavedItemsSlice, SavedItemStatus } from '@/redux/modules/item/saved/savedItems.types';
import { fetchBidderData } from '@/redux/modules/account/user/user.actions';
import { fetchSavedItems, submitSaveItem, submitUnsaveItem } from '@/redux/modules/item/saved/savedItems.actions';
import { FulfilledActionFromAsyncThunk } from '@/redux/createAsyncThunk';
import { LOG_OUT_BIDDER, LogOutBidderSuccessAction } from '@/redux/modules/account/logout/logout.actions';
import { submitLogin, submitLoginWithToken } from '@/redux/modules/account/login/login.actions';

const updateOnLogin = (
    slice: SavedItemsSlice,
    action:
        | FulfilledActionFromAsyncThunk<typeof fetchBidderData>
        | FulfilledActionFromAsyncThunk<typeof submitLogin>
        | FulfilledActionFromAsyncThunk<typeof submitLoginWithToken>
) => {
    if (action.payload.savedItemsTime) {
        action.payload.savedItemsTime.forEach((item) => {
            slice.savedItems[item.itemId] = {
                itemId: Number(item.itemId),
                saveTs: item.saveTs,
                status: SavedItemStatus.Saved,
            };
        });
    }
};

const savedItemsSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(fetchSavedItems.pending, (slice) => {
            slice.loading = true;
            slice.past = {
                ...slice.past,
                loading: true,
            };
            slice.upcoming = {
                ...slice.upcoming,
                loading: true,
            };
        });
        builder.addCase(fetchSavedItems.fulfilled, (slice, { meta, payload }) => {
            const pastIds = payload.tab === 'ended' ? payload.items.map(({ itemId }) => itemId) : slice.past.ids;
            const upcomingIds =
                payload.tab === 'available' ? payload.items.map(({ itemId }) => itemId) : slice.upcoming.ids;

            slice.ids = payload.items.map(({ itemId }) => itemId);
            slice.loaded = Date.now();
            slice.loading = false;
            slice.pagination = {
                ...defaultSavedItemsSlice.pagination,
                ...meta.arg,
                totalRecords: payload.totalRecords || payload.items.length || 0,
            };
            slice.past = {
                ids: pastIds,
                loaded: payload.tab === 'ended' ? Date.now() : slice.past.loaded,
                loading: false,
            };
            slice.upcoming = {
                ids: upcomingIds,
                loaded: payload.tab === 'available' ? Date.now() : slice.upcoming.loaded,
                loading: false,
            };
        });
        builder.addCase(fetchSavedItems.rejected, (slice) => {
            slice.loading = false;
            slice.past = {
                ...slice.past,
                loading: false,
            };
            slice.upcoming = {
                ...slice.upcoming,
                loading: false,
            };
        });

        builder.addCase(submitSaveItem.pending, (slice, { meta }) => {
            slice.savedItems[meta.arg.itemId] = {
                itemId: meta.arg.itemId,
                saveTs: Date.now(),
                status: SavedItemStatus.Saved,
            };
        });
        builder.addCase(submitSaveItem.rejected, (slice, { meta }) => {
            delete slice.savedItems[meta.arg.itemId];
        });

        builder.addCase(submitUnsaveItem.pending, (slice, { meta }) => {
            if (slice.savedItems[meta.arg]) {
                slice.savedItems[meta.arg].status = SavedItemStatus.Removed;
            }
        });
        builder.addCase(submitUnsaveItem.rejected, (slice, { meta }) => {
            if (slice.savedItems[meta.arg]) {
                slice.savedItems[meta.arg].status = SavedItemStatus.Saved;
            }
        });

        builder.addCase(fetchBidderData.fulfilled, updateOnLogin);
        builder.addCase(submitLogin.fulfilled, updateOnLogin);
        builder.addCase(submitLoginWithToken.fulfilled, updateOnLogin);

        builder.addCase<typeof LOG_OUT_BIDDER, LogOutBidderSuccessAction>(LOG_OUT_BIDDER, () => defaultSavedItemsSlice);
    },
    initialState: defaultSavedItemsSlice,
    name: 'saveItem',
    reducers: {},
});

export const { reducer: savedItemsReducer } = savedItemsSlice;
