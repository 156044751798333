import { createSlice } from '@reduxjs/toolkit';
import { defaultHelpModalSlice } from './helpModalSlice.types';
import {
    selectRecipient,
    selectTopic,
    showGetHelpMessenger,
    showMessageSent,
    showPolicyInfo,
} from './helpModalSlice.actions';

const helpModalSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(showGetHelpMessenger.fulfilled, (slice, { payload }) => {
            slice.showMessenger = payload;
            slice.showMessageSent = false;
            slice.showPolicyInfo = false;
        });
        builder.addCase(showMessageSent.fulfilled, (slice, { payload }) => {
            slice.showMessageSent = payload;
            slice.showMessenger = false;
            slice.showPolicyInfo = false;
            slice.activeTopic = '';
        });
        builder.addCase(showPolicyInfo.fulfilled, (slice, { payload }) => {
            slice.showPolicyInfo = payload;
            slice.showMessenger = false;
            slice.showMessageSent = false;
        });
        builder.addCase(selectTopic.fulfilled, (slice, { payload }) => {
            slice.activeTopic = payload;
            slice.showMessageSent = false;
            slice.showMessenger = false;
            slice.showPolicyInfo = false;
        });
        builder.addCase(selectRecipient.fulfilled, (slice, { payload }) => {
            slice.messageRecipient = payload;
        });
    },
    initialState: defaultHelpModalSlice,
    name: 'helpModalSlice',
    reducers: {},
});

export const { reducer: helpModalReducer } = helpModalSlice;
