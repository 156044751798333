import { MessageRecipient } from '@/components/Modals/GetHelp/enums/GetHelpModal.enum';

export type HelpModalSlice = {
    activeTopic: string;
    messageRecipient: MessageRecipient;
    showMessageSent: boolean;
    showMessenger: boolean;
    showPolicyInfo: boolean;
};

export const defaultHelpModalSlice: HelpModalSlice = {
    activeTopic: '',
    messageRecipient: MessageRecipient.House,
    showMessageSent: false,
    showMessenger: false,
    showPolicyInfo: false,
};
