import { createSlice } from '@reduxjs/toolkit';
import { defaultUserBidPlacedItemsSlice } from './userBidPlacedItems.types';
import { loadUserPlacedBidItemSummaries } from './userBidPlacedItems.actions';

const userBidPlacedItemsSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(loadUserPlacedBidItemSummaries.pending, (slice, { meta }) => {
            // If the user switches tabs from 'upcoming' to 'past' in my-bids page, don't show stale items
            if (meta.arg.status !== slice.params.status) {
                slice.itemIds = [];
                slice.totalRecords = 0;
            }

            slice.params = meta.arg;
            slice.loading = true;
            slice.error = false;
            slice.userHasPastBids = true;
            slice.userHasUpcomingBids = true;
        });
        builder.addCase(loadUserPlacedBidItemSummaries.fulfilled, (slice, { meta, payload }) => {
            slice.params = meta.arg;
            slice.itemIds = payload.items.map(({ itemId }) => itemId);
            slice.totalRecords = payload.totalRecords;
            slice.loading = false;
            slice.error = false;
            slice.userHasPastBids = payload.hasPastBids;
            slice.userHasUpcomingBids = payload.hasUpcomingBids;
        });
        builder.addCase(loadUserPlacedBidItemSummaries.rejected, (slice) => {
            slice.itemIds = [];
            slice.totalRecords = 0;
            slice.loading = false;
            slice.error = true;
            slice.userHasPastBids = true;
            slice.userHasUpcomingBids = true;
        });
    },
    initialState: defaultUserBidPlacedItemsSlice,
    name: 'userBidPlacedItems',
    reducers: {},
});

export const { reducer: userBidPlacedItemsSliceReducer } = userBidPlacedItemsSlice;
