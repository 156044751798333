import { ApiHosts, handleResponseOld, makeDelete, makeGet, makePost } from '@/redux/api/helpers';
import { FetchItemsSummariesResponse } from '@/types/item/ItemSummary';
import { MyFavoritesItemStatusTab } from '@/pages/MyItems/MyFavorites/types/MyFavoritesPage.types';
import { SearchSortAndDirection } from '@/types/search/enums/sortTypes';

/**
 * @category Item API
 * @see getSavedItemCounts
 */
type GetSavedItemCountsParams = {
    deployment: string;
    itemIds: number[];
};
/**
 * Retrieves an item's saved counts via Get request to saved item crows
 * @function getSavedItemCounts
 * @category Saved Items Crows
 * @param {GetSavedItemCountsParams}
 * @see https://saveditem-crows-DEPLOYMENT.liveauctioneers.com/services/saveditem-crows/saveditemscount
 */
export const getSavedItemCounts = ({ deployment, itemIds }: GetSavedItemCountsParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'saveditemscount',
            deployment,
            path: ApiHosts.Crows,
        });
        request.query({ lotIds: itemIds.join(',') });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });

/**
 * @category Saved Items Crows
 * @see getSavedItems
 */
export type SavedItemQueryType = {
    keyword?: string;
    page?: number;
    pageSize?: 24 | 48 | 72 | 96 | 120;
    sort?: SearchSortAndDirection;
    status: MyFavoritesItemStatusTab;
};

type FetchSavedItemsParams = {
    authToken: string;
    bidderId: number;
    deployment: string;
    query?: SavedItemQueryType;
};

/**
 * Retrieves an item's saved counts via Post request to Item API
 * @function getSavedItemCounts
 * @category Item API
 * @param {GetSavedItemCountsParams}
 * @see https://item-api-DEPLOYMENT.liveauctioneers.com/saved-items/count
 * @see https://github.com/LIVEauctioneers/item-api/blob/master/src/saved-search/routes/get-saved-search-items.ts
 */
export const getSavedItems = ({ authToken, bidderId, deployment, query }: FetchSavedItemsParams) =>
    new Promise<FetchItemsSummariesResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: `bidder/${bidderId}/saveditems`,
            authToken,
            deployment,
            path: ApiHosts.SavedItems,
        });
        request.query(query);
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });

/**
 * @category Item API
 * @see postSaveItem
 */
type PostSaveItemParams = {
    authToken: string;
    bidderId: number;
    catalogId: number;
    deployment: string;
    itemId: number;
};
/**
 * Saves an item favorite via Post to Item API service
 * @function postSaveItem
 * @category Saved Items Crows
 * @param {PostSaveItemParams}
 * @see https://saveditem-crows-DEPLOYMENT.liveauctioneers.com/services/saveditem-crows/saveditems
 */
export const postSaveItem = ({ authToken, bidderId, catalogId, deployment, itemId }: PostSaveItemParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({
            apiPath: 'saveditems',
            authToken,
            deployment,
            path: ApiHosts.Crows,
        });
        request.query({ bidderId });
        request.send({ catalogId, lotId: itemId });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });

/**
 * @category Item API
 * @see postUnsaveItem
 */
type PostUnsaveItemParams = {
    authToken: string;
    bidderId: number;
    deployment: string;
    itemId: number;
};
/**
 * Removes an item favorite via Post to Item API service
 * @function postUnsaveItem
 * @category Saved Items Crows
 * @param {PostUnsaveItemParams}
 * @see https://saveditem-crows-DEPLOYMENT.liveauctioneers.com/services/saveditem-crows/saveditems
 */
export const postUnsaveItem = ({ authToken, bidderId, deployment, itemId }: PostUnsaveItemParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makeDelete({
            apiPath: 'saveditems',
            authToken,
            deployment,
            path: ApiHosts.Crows,
        });
        request.query({ bidderId, lotId: itemId });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });
