import {
    UserBidPlacedItemsBidStatusEnum,
    UserBidPlacedItemsSortEnum,
    UserBidPlacedItemsStatusEnum,
} from '@/types/item/UserBidPlacedItem';
import type { ItemSummary } from '@/types/item/ItemSummary';

export type UserBidPlacedItemsParams = {
    bidStatus: UserBidPlacedItemsBidStatusEnum;
    keyword: string;
    page: number;
    pageSize: 24 | 48 | 72 | 96 | 120;
    sort: UserBidPlacedItemsSortEnum;
    status: UserBidPlacedItemsStatusEnum;
};

export type UserBidPlacedItemsState = {
    error: boolean;
    itemIds: number[];
    loading: boolean;
    params: UserBidPlacedItemsParams;
    totalRecords: number;
    userHasPastBids: boolean;
    userHasUpcomingBids: boolean;
};

export const defaultUserBidPlacedItemsSlice: UserBidPlacedItemsState = {
    error: false,
    itemIds: [],
    loading: false,
    params: {
        bidStatus: UserBidPlacedItemsBidStatusEnum.LEADING,
        keyword: '',
        page: 1,
        pageSize: 24,
        sort: UserBidPlacedItemsSortEnum.SALE_START_ASC,
        status: UserBidPlacedItemsStatusEnum.UPCOMING,
    },
    totalRecords: 0,
    userHasPastBids: true,
    userHasUpcomingBids: true,
};

export type FetchUserPlacedBidItemSummariesResponse = {
    error: boolean;
    payload: {
        hasPastBids: boolean;
        hasUpcomingBids: boolean;
        items: ItemSummary[];
        pageCount: number;
        recordCount: number;
        totalRecords: number;
    };
};
