import { ApiHosts, handleResponseOld, makeGet } from '@/redux/api/helpers';
import { FetchItemsSummariesResponse, transformItemSummary } from '@/types/item/ItemSummary';
import { SearchSortAndDirection } from '@/types/search/enums/sortTypes';

/**
 * @category ItemSummary API
 * @see fetchItemsByIds
 */
type FetchItemSummariesByIdsParams = {
    authToken: string;
    deployment: string;
    identifier: string;
    itemIds: number[];
    // Used on the console to ensure the bidder's bidding status is correct on page load
    liveStateFetch?: boolean;
    similarItems?: boolean;
};

/**
 * Fetches item info by given item ids via Post to ItemSummary service
 * @function fetchItemSummariesByIds
 * @category ItemSummary API
 * @param {FetchItemSummariesByIdsParams}
 */
export const fetchItemSummariesByIds = ({
    authToken,
    deployment,
    identifier,
    itemIds,
    liveStateFetch,
    similarItems,
}: FetchItemSummariesByIdsParams) =>
    new Promise<FetchItemsSummariesResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'items',
            authToken,
            deployment,
            path: ApiHosts.ItemModel,
        });
        request.query({
            identifier,
            liveStateFetch,
            lotIds: itemIds.join(','),
            similarItems,
        });
        request.end((err, response) =>
            handleResponseOld({
                err,
                reject,
                resolve,
                response,
                transform: transformItemSummary,
            })
        );
    });

export type FetchItemSummariesByCatalogIdQueryType = {
    buyNow?: boolean;
    index?: number;
    keyword?: string;
    page?: number;
    pageSize?: number | 'all';
    preview?: boolean;
    sort?: SearchSortAndDirection;
};

type FetchItemSummariesByCatalogIdParams = {
    authToken: string;
    catalogId: number;
    deployment: string;
    query: FetchItemSummariesByCatalogIdQueryType;
};

/**
 * Fetches item info by given item ids via Post to ItemSummary service
 * @function fetchItemSummariesByCatalogId
 * @category ItemSummary API
 * @param {FetchItemSummariesByIdsParams}
 */
export const fetchItemSummariesByCatalogId = ({
    authToken,
    catalogId,
    deployment,
    query,
}: FetchItemSummariesByCatalogIdParams) =>
    new Promise<FetchItemsSummariesResponse>((resolve: Function, reject: Function) => {
        const request = makeGet({
            apiPath: `catalog/${catalogId}`,
            authToken,
            deployment,
            path: ApiHosts.ItemModel,
        });
        request.query(query);
        request.end((err, response) =>
            handleResponseOld({
                err,
                reject,
                resolve,
                response,
                transform: transformItemSummary,
            })
        );
    });
