import { AsyncThunkConfigWithRejectValue, createAsyncThunk } from '@/redux/createAsyncThunk';
import { fetchUserPlacedBidItemSummaries } from './userBidPlacedItems.api';
import { getAuthToken, getBidderId } from '@/redux/modules/account/user/user.selectors';
import { getDeployment } from '@/redux/modules/config';
import type { FetchUserPlacedBidItemSummariesResponse, UserBidPlacedItemsParams } from './userBidPlacedItems.types';

export const loadUserPlacedBidItemSummaries = createAsyncThunk<
    FetchUserPlacedBidItemSummariesResponse['payload'],
    UserBidPlacedItemsParams,
    AsyncThunkConfigWithRejectValue
>('la/ui/userBidPlacedItems/loadUserPlacedBidItemSummaries', async (params, { getState, rejectWithValue }) => {
    const state = getState();
    const authToken = getAuthToken(state);
    const bidderId = getBidderId(state);
    const deployment = getDeployment(state);

    try {
        const { payload } = await fetchUserPlacedBidItemSummaries({
            authToken,
            bidderId,
            deployment,
            params,
        });
        return payload;
    } catch (error) {
        return rejectWithValue(new Error('Error in fetching user place bid item summaries'));
    }
});
