import { AuctionStatus } from '@/types/LiveAuctionState';
import { createAsyncThunk } from '@/redux/createAsyncThunk';
import { fetchDataForEndedItemPage } from '@/redux/modules/itemPage/endedItemPage.actions';
import { fetchDataForLiveItemPage } from '@/redux/modules/itemPage/liveItemPage.actions';
import { fetchDataForUpcomingItemPage } from '@/redux/modules/itemPage/upcomingItemPage.actions';
import { fetchItemCarouselsIfNeeded } from '../itemCarousel/itemCarousel.actions';
import { fetchItemDetailsIfNeeded } from '../itemDetail';
import { fetchItemFacetsIfNeeded } from '../itemFacets';
import { fetchItemSummaries } from '@/redux/modules/item/summary/itemSummary.actions';
import { fetchSavedItemCountsIfNeeded } from '../savedItemCount';
import { fetchTopSellersIfNeeded } from '../topRatedSellers';
import { getAreUrlsEqual, getItemUrl, getPriceResultsUrl, isPriceResultsPage } from '@/utils/urls';
import {
    getItemModelBuyNowAvailable,
    getItemModelCatalogStatus,
    getItemModelSlug,
    getItemModelTitle,
} from '../item/summary/itemSummary.selectors';
import { isUserLoggedIn } from '@/redux/modules/account/user/user.selectors';

export const fetchDataForItemPage = createAsyncThunk<
    Promise<unknown>,
    {
        itemId: number;
        location?: { pathname: string; search: string };
        redirect?: (url: string, permanent?: boolean) => void;
        referrer?: string;
    }
>(
    'la/domain/itemPage/fetchDataForItemPage',
    async ({ itemId, location, redirect, referrer = '' }, { dispatch, getState }) => {
        await Promise.all([
            dispatch(
                fetchItemSummaries({
                    identifier: 'item-page-main',
                    itemIds: [itemId],
                    similarItems: true,
                })
            ),
            dispatch(fetchItemFacetsIfNeeded([itemId])),
            dispatch(fetchItemDetailsIfNeeded([itemId])),
            dispatch(fetchSavedItemCountsIfNeeded([itemId])),
            dispatch(fetchItemCarouselsIfNeeded(itemId)),
            dispatch(fetchTopSellersIfNeeded()),
        ]);

        const state = getState();
        const isLoggedIn = isUserLoggedIn(state);
        const title = getItemModelTitle(state, itemId);
        const catalogStatus = getItemModelCatalogStatus(state, itemId);
        const buyNow = getItemModelBuyNowAvailable(state, itemId);
        const slug = getItemModelSlug(state, itemId);

        // These redirects we're only going to do on the server so only when window is undefined.
        if (typeof window === 'undefined') {
            const { pathname, search } = location;
            const itemUrl = getItemUrl(itemId, title);

            // If you click on a link from liveauctioneers.com to an item page, we don't want to redirect
            const liveAuctioneersReferrer = referrer.includes('liveauctioneers.com');

            // Handle the price-results redirect
            if (catalogStatus === AuctionStatus.Done && slug && !isLoggedIn && !liveAuctioneersReferrer && !buyNow) {
                return redirect(getPriceResultsUrl(slug), true);
            }

            // Handle the bad url redirect where the itemId is good but the item title is not
            if (!getAreUrlsEqual(pathname, itemUrl) && !isPriceResultsPage(location.pathname) && title) {
                // goodUrl is found, return a permanent redirect with the better url
                if (search) {
                    return redirect(`${itemUrl}${search}`, true);
                }
                return redirect(itemUrl, true);
            }
        }

        if (catalogStatus === AuctionStatus.Done) {
            return dispatch(fetchDataForEndedItemPage({ itemId }));
        } else if (catalogStatus === 'online') {
            return dispatch(fetchDataForUpcomingItemPage({ itemId }));
        } /* the other case is catalogStatus === 'live' which is handled here */ else {
            return dispatch(fetchDataForLiveItemPage({ itemId }));
        }
    }
);
