import { AppDispatch, AppGetState, GlobalState } from '@/redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { emptyItemDetail, ItemDetail } from '@/types/item/ItemDetail';
import { getDeployment } from './config';
import api from '@/redux/api/itemDetail';
import cloneDeep from 'lodash/cloneDeep';
import difference from 'lodash/difference';
import ms from 'ms';
import union from 'lodash/union';

/* Action Types */
export const LOAD_ITEM_DETAILS_FAIL = 'la/domain/itemDetail/LOAD_FAIL';
export const LOAD_ITEM_DETAILS_REQUEST = 'la/domain/itemDetail/LOAD_REQUEST';
export const LOAD_ITEM_DETAILS_SUCCESS = 'la/domain/itemDetail/LOAD_SUCCESS';

const REDUX_STORE_TIME = ms('30m');

/* reducer */
export type ItemDetailSlice = {
    byId: { [itemId: number]: ItemDetail };
    loaded: { [itemId: number]: number };
    loading: number[];
};

export const defaultItemDetailSlice: ItemDetailSlice = {
    byId: {},
    loaded: {},
    loading: [],
};

export default function reducer(state = defaultItemDetailSlice, action: any = {}): ItemDetailSlice {
    let existing: ItemDetailSlice['byId'];
    let loaded: ItemDetailSlice['loaded'];
    let loading: ItemDetailSlice['loading'];
    let time: number;

    switch (action.type) {
        case LOAD_ITEM_DETAILS_FAIL:
            return {
                ...state,
                loading: difference(state.loading, action.meta.itemIds),
            };
        case LOAD_ITEM_DETAILS_REQUEST:
            return {
                ...state,
                loading: union(state.loading, action.payload),
            };
        case LOAD_ITEM_DETAILS_SUCCESS:
            existing = cloneDeep(state.byId);
            loaded = { ...state.loaded };
            loading = cloneDeep(state.loading);
            time = action.meta.actionTime;

            if (action.payload.itemDetails) {
                action.payload.itemDetails.forEach((item) => {
                    existing[item.itemId] = { ...item };
                    loaded[item.itemId] = time;
                    loading = difference(loading, [item.itemId]);
                });
            }

            return {
                ...state,
                byId: existing,
                loaded,
                loading,
            };
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state: GlobalState) => state;
const itemDetailSlice = createSelector(stateSelector, (state) => state.itemDetail);
const idSelector = (_: GlobalState, id: number) => id;

const byIdSelector = createSelector(itemDetailSlice, ({ byId }) => byId);
const loadedSelector = createSelector(itemDetailSlice, ({ loaded }) => loaded);
const loadingSelector = createSelector(itemDetailSlice, ({ loading }) => loading);

// takes in a (state, id) and passes both params to every function on
// the way down.
export const getItemDetail = createSelector([byIdSelector, idSelector], (byId, id) => byId[id] || emptyItemDetail);
export const getItemDetailDescription = createSelector(getItemDetail, ({ description }) => description || '');
export const getItemDetailAgentId = createSelector(getItemDetail, ({ agentId }) => agentId);
export const getItemDetailConditionReport = createSelector(
    getItemDetail,
    ({ conditionReport }) => conditionReport || ''
);
export const getItemDetailDimensions = createSelector(getItemDetail, ({ dimensions }) => dimensions || '');
export const getItemDetailShippingDimensions = createSelector(
    getItemDetail,
    ({ shippingDimensions }) => shippingDimensions || ''
);
export const getItemDetailShippingWeight = createSelector(getItemDetail, ({ shippingWeight }) => shippingWeight || '');
export const getItemDetailWeight = createSelector(getItemDetail, ({ weight }) => weight || '');
export const getItemDetailDomesticFlatShipping = createSelector(
    getItemDetail,
    ({ domesticFlatShipping }) => domesticFlatShipping
);
export const getItemDetailExternalUrl = createSelector(getItemDetail, ({ externalUrl }) => externalUrl || '');

export const getIsItemDetailLoaded = createSelector([loadedSelector, idSelector], (loaded, id) =>
    loaded.hasOwnProperty(id)
);

export const getHasRequiredShippingDimensions = createSelector(getItemDetail, (itemDetail) => {
    const shippingDimensions = itemDetail.shippingDimensions || '';
    const parts = shippingDimensions.split(' ').filter((i) => i !== 'x');
    // we are checking to see that they have at least 3 parts and that the last part is a "unit"
    return parts.length >= 3 && isNaN(Number(parts[parts.length - 1]));
});

const getLoadTimeForItemDetail = createSelector([loadedSelector, idSelector], (loaded, id) => loaded[id] || 0);

const isItemDetailLoading = createSelector([loadingSelector, idSelector], (loading, id) => loading.includes(id));

const shouldFetchItemDetail = (state, itemId) => {
    if (!itemId) {
        return false;
    }
    const item = getItemDetail(state, itemId);
    if (item) {
        const loaded = getLoadTimeForItemDetail(state, itemId);
        const time = Date.now();
        const diff = time - loaded;
        if (diff < REDUX_STORE_TIME) {
            return false;
        }
    }
    const loading = isItemDetailLoading(state, itemId);
    return !loading;
};

const whichItemDetailsNeeded = (state, itemIds) => itemIds.filter((itemId) => shouldFetchItemDetail(state, itemId));

/* ACTION CREATORS */
const loadItemDetails = (itemIds) => async (dispatch, getState) => {
    try {
        const state = getState();
        const deployment = getDeployment(state);

        dispatch({
            payload: itemIds,
            type: LOAD_ITEM_DETAILS_REQUEST,
        });

        const response = await api.fetchItemDetailsByIds({
            deployment,
            itemIds,
        });

        dispatch({
            meta: { actionTime: Date.now(), itemIds },
            payload: response.data,
            type: LOAD_ITEM_DETAILS_SUCCESS,
        });
    } catch (error) {
        dispatch({
            error: true,
            meta: { itemIds },
            payload: error,
            type: LOAD_ITEM_DETAILS_FAIL,
        });
    }
};

export const fetchItemDetailsIfNeeded = (itemIds: number[]) => async (dispatch: AppDispatch, getState: AppGetState) => {
    const needed = whichItemDetailsNeeded(getState(), itemIds);
    if (needed.length) {
        return dispatch(loadItemDetails(needed));
    }
    return Promise.resolve();
};
