import { createAsyncThunk } from '@/redux/createAsyncThunk';
import { fetchItemCarousels } from './itemCarousel.api';
import { FetchItemCarouselsPayload } from './itemCarousel.types';
import { getAuthToken } from '@/redux/modules/account/user/user.selectors';
import { getClientIpAddress } from '../browser';
import { getDeployment } from '../config';
import { getIsBot } from '../analytics';
import { shouldFetchCarousel } from './itemCarousel.selectors';

export const loadItemCarousels = createAsyncThunk<FetchItemCarouselsPayload, number>(
    'la/domain/items/fetchItemCarousels',
    async (itemId, { getState }) => {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        const ipAddress = getClientIpAddress(state);
        const isBot = getIsBot(state);
        const { payload: carousels } = await fetchItemCarousels({
            authToken,
            deployment,
            ipAddress,
            isBot,
            itemId,
        });
        return { carousels, itemId };
    }
);

export const fetchItemCarouselsIfNeeded = createAsyncThunk<void, number>(
    'la/domain/items/fetchItemCarouselsIfNeeded',
    async (itemId, { dispatch, getState }) => {
        const state = getState();
        if (shouldFetchCarousel(state, itemId)) {
            await dispatch(loadItemCarousels(itemId));
        }
    }
);
