import { createSelector } from '@reduxjs/toolkit';
import { getItemSummaries } from '../summary/itemSummary.selectors';
import { GlobalState } from '@/redux/store';
import { UserBidPlacedItemsStatusEnum } from '@/types/item/UserBidPlacedItem';
import uniq from 'lodash/uniq';

const getBidPlacedItemSummariesSlice = ({ userBidPlacedItems }: GlobalState) => userBidPlacedItems;

// get the item summaries for each itemId stored in the userbidPlacedItems slice
export const getBidPlacedItemSummaries = createSelector(
    (state: GlobalState) => {
        const itemIds = getBidPlacedItemSummariesSlice(state).itemIds;
        return getItemSummaries(state, itemIds);
    },
    (items) => items
);

export const isLoading = createSelector(getBidPlacedItemSummariesSlice, (state) => state.loading);
export const totalRecordsNum = createSelector(getBidPlacedItemSummariesSlice, (state) => state.totalRecords);
export const getBidPlacedItemsParams = createSelector(getBidPlacedItemSummariesSlice, (state) => state.params);
export const getBidPlacedItemIds = createSelector(getBidPlacedItemSummariesSlice, (state) => state.itemIds);
export const getBidPlacedCatalogIds = createSelector(getBidPlacedItemSummaries, (state) =>
    uniq(state.map((item) => item.catalogId))
);

/**
 * The my-bids page needs to know if the user has any ended/upcoming bids _at all_
 * regardless of the current filters, if false the frontend will hide the pagination controls
 * and show a message about placing bids
 */
export const userHasBids = createSelector([getBidPlacedItemSummariesSlice], (state) => {
    if (state.params.status === UserBidPlacedItemsStatusEnum.ENDED) {
        return state.userHasPastBids;
    } else if (state.params.status === UserBidPlacedItemsStatusEnum.UPCOMING) {
        return state.userHasUpcomingBids;
    }

    return false;
});
