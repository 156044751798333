import { createAsyncThunk } from '@/redux/createAsyncThunk';
import { ExperimentVariants } from './kameleoon.types';

export const storeVisitorCode = createAsyncThunk<string, string>('la/ui/kameleoon/storeVisitorCode', (code) => code);

export const fetchActiveExperimentVariants = createAsyncThunk<ExperimentVariants, ExperimentVariants>(
    'la/ui/kameleoon/storeVariants',
    (variants) => variants
);

export const changeActiveVariants = createAsyncThunk<ExperimentVariants, ExperimentVariants>(
    'la/ui/kameleoon/changeActiveVariants',
    (variant) => variant
);

export const resetVariants = createAsyncThunk<void, void>('la/ui/kameleoon/resetVariants', () => {});
