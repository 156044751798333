import { BiddingInfo } from '@/types/BiddingInfo';
import { BuyNow } from '@/types/BuyNow';
import { Catalog } from '@/types/Catalog';
import { createAsyncThunk } from '@/redux/createAsyncThunk';
import { fetchItemSummariesByIds } from '@/redux/modules/item/summary/itemSummary.api';
import { FetchItemSummariesPayload, FetchItemSummaryPayload, ITEM_SUMMARY_FETCH_CHUNK_SIZE } from './itemSummary.types';
import { getAuthToken } from '@/redux/modules/account/user/user.selectors';
import { getDeployment } from '@/redux/modules/config';
import { Item } from '@/types/item/Item';
import { ItemSummary } from '@/types/item/ItemSummary';
import { LOAD_STOREFRONT_ITEMS_SUCCESS } from '@/redux/modules/actions';
import { Seller } from '@/types/Seller';
import { shouldFetchItem, whichItemSummariesNeeded } from './itemSummary.selectors';
import { TypedActionWithPayload } from '@/types/redux';
import chunk from 'lodash/chunk';

export const fetchItemSummaries = createAsyncThunk<ItemSummary[], FetchItemSummariesPayload>(
    'la/ui/itemSummary/fetchItemSummaries',
    async ({ identifier, itemIds, liveStateFetch = false, similarItems }, { getState }) => {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        const { payload } = await fetchItemSummariesByIds({
            authToken,
            deployment,
            identifier,
            itemIds,
            liveStateFetch,
            similarItems,
        });
        return payload.items;
    }
);

export const fetchItemSummaryIfNeeded = createAsyncThunk<void, FetchItemSummaryPayload>(
    'la/ui/itemSummary/fetchItemSummaryIfNeeded',
    async ({ force, identifier, itemId, liveStateFetch = false }, { dispatch, getState }) => {
        if (force || shouldFetchItem(getState(), itemId)) {
            await dispatch(fetchItemSummaries({ identifier, itemIds: [itemId], liveStateFetch }));
        }
    }
);

export const fetchItemSummariesIfNeeded = createAsyncThunk<void, FetchItemSummariesPayload>(
    'la/ui/itemSummary/fetchItemSummariesIfNeeded',
    async ({ force, identifier, itemIds, liveStateFetch, similarItems }, { dispatch, getState }) => {
        const needed = force ? itemIds : whichItemSummariesNeeded(getState(), itemIds);
        if (needed.length > 0) {
            // If the array of items is too long we won't be able to send it.
            // We'll chunk it up into blocks of 400 that we can request separately
            const chunks = chunk(needed, ITEM_SUMMARY_FETCH_CHUNK_SIZE);
            const promises = chunks.map((chunk) =>
                dispatch(
                    fetchItemSummaries({
                        identifier,
                        itemIds: chunk,
                        liveStateFetch,
                        similarItems,
                    })
                )
            );
            await Promise.all(promises);
        }
        return Promise.resolve();
    }
);

// TODO: make typed actions for these payload types and make a new type by combining those instead of doing this manually
// @deprecated Using this until LOAD_STOREFRONT_ITEMS_SUCCESS is toolkit-ified
export type LoadItemDataFromElsewhereId = typeof LOAD_STOREFRONT_ITEMS_SUCCESS;
// @deprecated Using this until LOAD_STOREFRONT_ITEMS_SUCCESS is toolkit-ified
export type LOAD_ITEM_DATA_FROM_ELSEWHERE_SUCCESS = TypedActionWithPayload<
    LoadItemDataFromElsewhereId,
    {
        biddingInfos?: BiddingInfo[];
        buyNows?: BuyNow[];
        catalogs?: Catalog[];
        items?: Item[];
        sellers?: Seller[];
    },
    {
        actionTime: number;
    }
>;
