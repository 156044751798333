import {
    KameleoonExperimentIds,
    KameleoonExperiments,
    KameleoonExperimentsVariantIds,
} from '@/hooks/kameleoon/kameleoonExperiments';

export type ExperimentVariants = {
    [flag in KameleoonExperimentIds]: KameleoonExperimentsVariantIds[keyof KameleoonExperimentsVariantIds];
};

const experiments: ExperimentVariants = {} as ExperimentVariants;

for (const experiment of Object.values(KameleoonExperiments)) {
    experiments[experiment] = 'off';
}

export type KameleoonSlice = {
    activeVariants: ExperimentVariants;
    userChangedVariant: boolean;
    userSelectedVariants: ExperimentVariants;
    visitorCode: string;
};

export const defaultKameleoonSlice: KameleoonSlice = {
    activeVariants: experiments,
    userChangedVariant: false,
    userSelectedVariants: experiments,
    visitorCode: '',
};
