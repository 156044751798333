import { MyFavoritesItemStatusTab } from '@/pages/MyItems/MyFavorites/types/MyFavoritesPage.types';
import { SearchSortAndDirection } from '@/types/search/enums/sortTypes';

export enum SavedItemStatus {
    Removed = 'REMOVED',
    Saved = 'SAVED',
}

export type SavedItemsSlice = {
    ids: number[];
    loaded: number;
    loading: boolean;
    pagination: {
        keyword: string;
        page: number;
        pageSize: 24 | 48 | 72 | 96 | 120;
        results: number;
        sort: SearchSortAndDirection;
        status: MyFavoritesItemStatusTab;
        totalRecords: number;
    };
    past: {
        ids: number[];
        loaded: number;
        loading: boolean;
    };
    savedItems: {
        [id: number]: {
            itemId: number;
            saveTs: number;
            status: SavedItemStatus;
        };
    };
    upcoming: {
        ids: number[];
        loaded: number;
        loading: boolean;
    };
};

export const defaultSavedItemsSlice: SavedItemsSlice = {
    ids: [], // Old way we get ids for Saved items page(Will remove if MyItemsPage A/B test passes)
    loaded: 0,
    loading: false,
    pagination: {
        keyword: '',
        page: 1,
        pageSize: 24,
        results: 0,
        sort: SearchSortAndDirection.TimeStartingSoonest, // this value is ignored by `getSortStartingOption`
        status: MyFavoritesItemStatusTab.Available,
        totalRecords: 0,
    },
    past: {
        ids: [], // New way we get ids for Saved items page(Will remove if MyItemsPage A/B test fails)
        loaded: 0,
        loading: false,
    },
    savedItems: {},
    upcoming: {
        ids: [], // New way we get ids for Saved items page(Will remove if MyItemsPage A/B test fails)
        loaded: 0,
        loading: false,
    },
};
