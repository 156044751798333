import { createSelector } from '@reduxjs/toolkit';
import { GlobalState } from '@/redux/store';

import { REHYDRATE } from 'redux-persist/constants';

/* Action Types */
export const SET_HEADER_DROPDOWN_VISIBLE = 'SET_HEADER_DROPDOWN_VISIBLE';

/* reducer */
export type HeaderSearchDropdownSlice = {
    headerDropdownVisible: boolean;
};

export const defaultHeaderSearchDropdownSlice: HeaderSearchDropdownSlice = {
    headerDropdownVisible: false,
};

export default function reducer(
    state: HeaderSearchDropdownSlice = defaultHeaderSearchDropdownSlice,
    action: any = {}
): HeaderSearchDropdownSlice {
    switch (action.type) {
        case REHYDRATE:
            if (
                Object.prototype.hasOwnProperty.call(action.payload, 'persist') &&
                Object.prototype.hasOwnProperty.call(action.payload.persist, 'headerSearchDropdown')
            ) {
                const incoming = action.payload.persist.headerSearchDropdown;
                return { ...state, ...incoming };
            }

            return state;
        case SET_HEADER_DROPDOWN_VISIBLE:
            return {
                ...state,
                headerDropdownVisible: action.payload,
            };
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state: GlobalState) => state;

const headerSearchDropdownSlice = createSelector(stateSelector, (state) => state.persist.headerSearchDropdown);
export const isHeaderDropdownVisible = createSelector(
    headerSearchDropdownSlice,
    (state) => state.headerDropdownVisible
);

/* ACTION CREATORS */
export const setHeaderDropdownVisible = (show: boolean = true) => ({
    payload: show,
    type: SET_HEADER_DROPDOWN_VISIBLE,
});
