import { createAsyncThunk } from '@/redux/createAsyncThunk';
import { MessageRecipient } from '@/components/Modals/GetHelp/enums/GetHelpModal.enum';

export const showGetHelpMessenger = createAsyncThunk<boolean, boolean>(
    'la/ui/getHelpModal/showMessenger',
    (show) => show
);

export const showMessageSent = createAsyncThunk<boolean, boolean>('la/ui/getHelpModal/showMessageSent', (show) => show);

export const showPolicyInfo = createAsyncThunk<boolean, boolean>('la/ui/getHelpModal/showPolicyInfo', (show) => show);

export const selectTopic = createAsyncThunk<string, string>('la/ui/getHelpModal/selectTopic', (topic) => topic);

export const selectRecipient = createAsyncThunk<MessageRecipient, MessageRecipient>(
    'la/ui/getHelpModal/selectRecipient',
    (recipient) => recipient
);
