import { AsyncThunkConfigWithRejectValue, createAsyncThunk } from '@/redux/createAsyncThunk';
import { fetchItemFacetsIfNeeded, getItemFacets } from '../../itemFacets';
import { fetchItemSummaryIfNeeded } from '@/redux/modules/item/summary/itemSummary.actions';
import { fetchSavedItemCountsIfNeeded } from '@/redux/modules/savedItemCount';
import { getAmplitudePageNameFromPath } from '@/utils/getPageNameFromPath';
import { getAuthToken, getBidderId } from '@/redux/modules/account/user/user.selectors';
import { getDeployment } from '@/redux/modules/config';
import { getFullUrl } from '@/utils/analytics/utils/getFullUrl';
import { getItemSummary } from '@/redux/modules/item/summary/itemSummary.selectors';
import {
    getSavedItems,
    postSaveItem,
    postUnsaveItem,
    SavedItemQueryType,
} from '@/redux/modules/item/saved/savedItems.api';
import {
    getSaveEasyShippingItemAnalytics,
    getSaveItemAnalytics,
    getUnsaveItemAnalytics,
    trackConversionForAlgolia,
} from '@/redux/modules/analytics';
import { isItemSaved } from '@/redux/modules/item/saved/savedItems.selectors';
import { ItemSummary } from '@/types/item/ItemSummary';
import { Location } from 'react-router-dom';
import { MyFavoritesItemStatusTab } from '@/pages/MyItems/MyFavorites/types/MyFavoritesPage.types';
import { trackItemUnsavedAnalytics } from '@/utils/analytics';

export const SAVE_EASY_SHIPPING_ITEM_SUCCESS = 'la/ui/saveItem/saveEasyShippingItem/SUCCESS';

type SubmitSaveItemParams = {
    canHaveEasyShippingTag: boolean;
    itemId: number;
    location: Location;
};

type ToggleSaveItemResponse = {
    meta: ReturnType<typeof getSaveItemAnalytics>;
    payload: number;
};

export const submitSaveItem = createAsyncThunk<
    ToggleSaveItemResponse,
    SubmitSaveItemParams,
    AsyncThunkConfigWithRejectValue
>(
    'la/ui/saveItem/submitSaveItem',
    async ({ canHaveEasyShippingTag, itemId, location }, { dispatch, fulfillWithValue, getState, rejectWithValue }) => {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        const bidderId = getBidderId(state);
        const { catalogId } = getItemSummary(state, itemId);

        try {
            await postSaveItem({
                authToken,
                bidderId,
                catalogId,
                deployment,
                itemId,
            });
            if (!Boolean(getItemSummary(state, itemId).itemId)) {
                await dispatch(
                    fetchItemSummaryIfNeeded({
                        identifier: 'save-item',
                        itemId,
                    })
                );
            }

            if (!Boolean(getItemFacets(state, itemId).categories)) {
                await dispatch(fetchItemFacetsIfNeeded([itemId]));
            }

            const newState = getState();
            dispatch(trackConversionForAlgolia(itemId));

            if (canHaveEasyShippingTag) {
                dispatch({
                    meta: getSaveEasyShippingItemAnalytics(newState, itemId),
                    payload: itemId,
                    type: SAVE_EASY_SHIPPING_ITEM_SUCCESS,
                });
            }
            await dispatch(fetchSavedItemCountsIfNeeded([itemId], true));

            const savedItemAnalytics = getSaveItemAnalytics(state, itemId, location);

            return fulfillWithValue(
                { meta: savedItemAnalytics, payload: itemId },
                // @ts-expect-error this is right, but the typing is insane
                savedItemAnalytics
            );
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const submitUnsaveItem = createAsyncThunk<ToggleSaveItemResponse, number, AsyncThunkConfigWithRejectValue>(
    'la/ui/saveItem/submitUnsaveItem',
    async (itemId, { dispatch, getState, rejectWithValue }) => {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        const bidderId = getBidderId(state);

        try {
            await postUnsaveItem({
                authToken,
                bidderId,
                deployment,
                itemId,
            });
            if (!Boolean(getItemSummary(state, itemId).itemId)) {
                await dispatch(
                    fetchItemSummaryIfNeeded({
                        identifier: 'saved-item',
                        itemId,
                    })
                );
            }
            const newState = getState();
            await dispatch(fetchSavedItemCountsIfNeeded([itemId], true));

            trackItemUnsavedAnalytics({
                pageName: getAmplitudePageNameFromPath(window.location.pathname),
                url: getFullUrl(deployment, window.location.pathname),
            });

            return {
                meta: getUnsaveItemAnalytics(newState, itemId),
                payload: itemId,
            };
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export type FetchSavedItemsResponse = {
    items: ItemSummary[];
    tab: MyFavoritesItemStatusTab;
    totalRecords: number;
};

export const fetchSavedItems = createAsyncThunk<
    FetchSavedItemsResponse,
    SavedItemQueryType,
    AsyncThunkConfigWithRejectValue
>('la/ui/saveItem/fetchSavedItems', async (query, { getState, rejectWithValue }) => {
    const state = getState();
    const authToken = getAuthToken(state);
    const deployment = getDeployment(state);
    const bidderId = getBidderId(state);

    try {
        const { payload } = await getSavedItems({
            authToken,
            bidderId,
            deployment,
            query,
        });
        return {
            items: payload.items,
            tab: query.status,
            totalRecords: payload.totalRecords,
        };
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const toggleItemSave = createAsyncThunk<void, SubmitSaveItemParams>(
    'la/ui/saveItem/toggleItemSave',
    async ({ canHaveEasyShippingTag, itemId, location }, { dispatch, getState }) => {
        if (isItemSaved(getState(), itemId)) {
            await dispatch(submitUnsaveItem(itemId));
        } else {
            await dispatch(
                submitSaveItem({
                    canHaveEasyShippingTag,
                    itemId,
                    location,
                })
            );
        }
    }
);
