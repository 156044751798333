import { AppDispatch, AppGetState, GlobalState } from '@/redux/store';
import { AugmentedSearchQuery, AugmentedSearchQueryOptions, SearchQuery } from '@/types/search/searchQuery';
import { CategorySearchResultPayload, isErrorResponse } from './search/search.types';
import { createSelector } from '@reduxjs/toolkit';
import { fetchCategoryResults } from '@/redux/modules/search/search.api';
import { getAuthToken } from '@/redux/modules/account/user/user.selectors';
import {
    getCategoryWithGreatestResults,
    getSelectedCategoryFacetValue,
    getSelectedOptionFacetValues,
    getUpcomingAuctions,
} from '@/redux/modules/searchFacetAggregates.selectors';
import { getClientIpAddress } from './browser';
import { getCurrentSearch, getSearchUiState } from '@/redux/modules/search/search.selectors';
import { getDeployment } from './config';
import { getIsBot } from './analytics';
import { getItemSummary } from './item/summary/itemSummary.selectors';
import { getUserExcludedFacets } from './search/exclusions/searchExclusions.selectors';
import { isArchivedSearch } from '@/utils/queryParams';
import {
    LOAD_CATEGORY_RESULTS_FAIL,
    LOAD_CATEGORY_RESULTS_FAIL_ACTION,
    LOAD_CATEGORY_RESULTS_REQUEST,
    LOAD_CATEGORY_RESULTS_REQUEST_ACTION,
    LOAD_CATEGORY_RESULTS_SUCCESS,
    LOAD_CATEGORY_RESULTS_SUCCESS_ACTION,
} from './actions';
import { LOAD_COMBINATION_CATEGORY_SUCCESS } from './combinationCategory';
import { searchPromoted } from './searchPromoted';
import cloneDeep from 'lodash/cloneDeep';
import FacetTypes from '@/types/search/enums/facetTypes';

type LandingPageMetaDataType = {
    canonicalURL?: string;
    pageLabel: string;
    pageMetaDescription: string;
    pageMetaKeywords: string;
    pageTitle: string;
};

export type LandingPageStateType = {
    metaData: LandingPageMetaDataType;
};

// reducer
export const defaultLandingPageSlice: LandingPageStateType = {
    metaData: {
        pageLabel: '',
        pageMetaDescription: '',
        pageMetaKeywords: '',
        pageTitle: '',
    },
};

export default function reducer(state = defaultLandingPageSlice, action: any = {}): LandingPageStateType {
    switch (action.type) {
        case LOAD_COMBINATION_CATEGORY_SUCCESS:
            return {
                ...state,
                metaData: {
                    ...state.metaData,
                    // @ts-ignore
                    pageLabel: action.payload.pageTitle.length ? action.payload.pageTitle : state.metaData.pageLabel,
                    pageMetaDescription: action.payload.meta_description.length
                        ? action.payload.meta_description
                        : state.metaData.pageMetaDescription,
                    pageTitle: action.payload.meta_title ? action.payload.meta_title : state.metaData.pageTitle,
                },
            };
        case LOAD_CATEGORY_RESULTS_SUCCESS:
            let metaData = state.metaData;

            if (action.payload.landingPageMetaData) {
                metaData = {
                    // @ts-ignore
                    canonicalURL: action.payload.landingPageMetaData
                        ? action.payload.landingPageMetaData.canonicalURL
                        : '',
                    pageLabel: action.payload.landingPageMetaData.label,
                    pageMetaDescription: action.payload.landingPageMetaData.metaDescription,
                    pageMetaKeywords: action.payload.landingPageMetaData.metaKeywords,
                    pageTitle: action.payload.landingPageMetaData.pageTitle,
                };
            }

            return {
                ...state,
                metaData,
            };
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state: GlobalState) => state;
const landingPageSlice = createSelector(stateSelector, (state) => state.landingPage);

const metaDataSelector = createSelector(landingPageSlice, (state) => state.metaData);

const getPageMetaDescription = createSelector(metaDataSelector, (metaData) => metaData.pageMetaDescription);

const getPageMetaKeywords = createSelector(metaDataSelector, (metaData) => metaData.pageMetaKeywords);

const getPageTitle = createSelector(metaDataSelector, (metaData) => metaData.pageTitle);

export const getCanonicalURL = createSelector(metaDataSelector, (metaData) => metaData.canonicalURL);

export const getPageLabel = createSelector(metaDataSelector, (metaData) => {
    return metaData.pageLabel;
});

export const getCategoryLandingPageUrl = (params: any) => {
    return getLandingPageUrl(params, 'c');
};

export const getAuctionResultsLandingPageUrl = (params: any) => {
    return getLandingPageUrl(params, 'price-guide');
};

const getLandingPageUrl = (params, route) => {
    if (!params.categoryId || Number.isNaN(Number(params.categoryId))) {
        return `/${route}/`;
    } else if (params.categoryId2 && params.categoryName2) {
        return `/${route}/${params.categoryName}/${params.categoryName2}/${params.categoryId}/${params.categoryId2}/`;
    }
    return `/${route}/${params.categoryName}/${params.categoryId}/`;
};

export const getLandingPageMetaData = (state: GlobalState, params: any, pathname: any, queryParams: any) => {
    const { totalFound } = getSearchUiState(state);
    const canonicalURL = getCanonicalURL(state);
    const pageMetaDescription = getPageMetaDescription(state);
    const pageMetaKeywords = getPageMetaKeywords(state);
    const archivedSearch = isArchivedSearch(queryParams, pathname);
    const pageTitle = getPageTitle(state);
    const pageUrl = getCategoryLandingPageUrl(params);
    const categoryWithGreatestResults = getCategoryWithGreatestResults(state, archivedSearch);
    const totalUpcomingAuctions = getUpcomingAuctions(state, archivedSearch);
    const selectedCreator = getSelectedOptionFacetValues(state, archivedSearch, FacetTypes.CREATOR_ID);
    const selectedMaterials = getSelectedOptionFacetValues(
        state,
        archivedSearch,
        FacetTypes.MATERIALS_AND_TECHNIQUES_ID
    );
    const selectedStyles = getSelectedOptionFacetValues(state, archivedSearch, FacetTypes.STYLE_PERIOD_ID);
    const selectedOrigin = getSelectedOptionFacetValues(state, archivedSearch, FacetTypes.ORIGIN_ID);
    const selectedCategory = getSelectedCategoryFacetValue(state, archivedSearch, undefined);

    const landingPageMetaData = {
        canonicalURL,
        categoryWithGreatestResults,
        pageMetaDescription,
        pageMetaKeywords,
        pageTitle,
        pageUrl,
        selectedCategory,
        selectedCreator,
        selectedMaterials,
        selectedOrigin,
        selectedStyles,
        totalFound,
        totalUpcomingAuctions,
    };

    return cloneDeep(landingPageMetaData);
};

/* ACTION CREATORS */
const categoryResultsFail = (): LOAD_CATEGORY_RESULTS_FAIL_ACTION => ({
    type: LOAD_CATEGORY_RESULTS_FAIL,
});

const categoryResultsRequest = (): LOAD_CATEGORY_RESULTS_REQUEST_ACTION => ({
    type: LOAD_CATEGORY_RESULTS_REQUEST,
});

const categoryResultsSuccess = (results: CategorySearchResultPayload): LOAD_CATEGORY_RESULTS_SUCCESS_ACTION => ({
    error: false,
    meta: { actionTime: Date.now() },
    payload: results,
    type: LOAD_CATEGORY_RESULTS_SUCCESS,
});

export const searchCategory =
    (searchQuery: SearchQuery, ids: number[], pageName?: string) =>
    async (dispatch: AppDispatch, getState: AppGetState) => {
        try {
            const state = getState();
            const deployment = getDeployment(state);
            const authToken = getAuthToken(state);
            const ipAddress = getClientIpAddress(state);
            const isBot = getIsBot(state);

            const currentSearch = getCurrentSearch(state);

            // Annotate facets which have user-defined search exclusions
            const searchExclusions = getUserExcludedFacets(state);
            const augmentedOptions: AugmentedSearchQueryOptions = Object.keys(searchExclusions).reduce(
                (options, facetWithExclusions) => ({
                    ...options,
                    [facetWithExclusions]: [
                        {
                            exclude: searchExclusions[facetWithExclusions] || [],
                            include: (searchQuery.options && searchQuery.options[facetWithExclusions]) || [],
                        },
                    ],
                }),
                {
                    ...searchQuery.options,
                    auctionHouse: [{ exclude: [], include: [] }],
                }
            );
            searchQuery.categories = ids?.length ? ids.map((id) => `${id}`) : searchQuery.categories;
            const augmentedSearchQuery: AugmentedSearchQuery = {
                ...(isBot ? { isBot } : {}),
                ...searchQuery,
                buyNow: Boolean(pageName) && !pageName?.includes('price-guide'),
                fullResults: true,
                options: augmentedOptions,
            };

            if (augmentedSearchQuery?.options?.auctionHouse) {
                augmentedSearchQuery?.options?.auctionHouse[0]?.exclude?.slice(0, 200);
            }

            const searchForCacheCheck = JSON.stringify(augmentedSearchQuery);

            if (searchForCacheCheck === currentSearch) {
                return Promise.resolve();
            }

            dispatch(categoryResultsRequest());

            const [result] = await Promise.all([
                fetchCategoryResults({
                    authToken,
                    deployment,
                    ids,
                    ipAddress,
                    searchQuery: augmentedSearchQuery,
                }),
                dispatch(searchPromoted({ ids, pageName, searchQuery })),
            ]);

            if (isErrorResponse(result)) {
                dispatch(categoryResultsFail());
            } else {
                result.payload.currentQueryKey = searchForCacheCheck;
                if (Boolean(augmentedSearchQuery?.searchTerm) && typeof result.payload === 'object') {
                    result.payload.sort = [];
                }
                dispatch(categoryResultsSuccess(result.payload));
            }
        } catch (error) {
            dispatch(categoryResultsFail());
        }
    };

export const getDeletedItemTitleIfNeeded = (state: GlobalState, query: any) => {
    let item;
    let returnTitle = '';
    if (query.deleted && !isNaN(query.deleted)) {
        item = getItemSummary(state, query.deleted) || {
            isDeleted: true,
            title: 'The item you were seeking',
        };
    }
    if (item && item.isDeleted && item.title) {
        returnTitle = item.title;
    }
    return returnTitle;
};

//TODO: rename all the categories to landing where appropriate
