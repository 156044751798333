import { Item } from '@/types/item/Item';

export type CarouselServerResponse = {
    items: Item[];
    title: string;
    viewMoreUrl: string;
};

export type Carousel = {
    itemIds: number[];
    title: string;
    viewMoreUrl: string;
};

export const emptyCarousel: Carousel = {
    itemIds: [],
    title: '',
    viewMoreUrl: '',
};

export type FetchItemCarouselsPayload = {
    carousels: CarouselServerResponse[];
    itemId: number;
};

export type FetchItemCarouselsResponse = {
    error: boolean;
    payload: CarouselServerResponse[];
};

/**
 * @category Search API
 * @see api.fetchItemCarousels
 * @see https://liveauctioneers.atlassian.net/browse/SW-958
 */
export type FetchItemCarouselsParams = {
    /** The IP Address of the client, for SSR passthrough */
    ipAddress: string;
    /** The is the client a bot for SSR passthrough */
    isBot: boolean;
    /** The item for which to return the carousels for */
    itemId: number;
};

export type ItemCarouselState = {
    byId: { [id: number]: Carousel[] };
    loading: boolean;
};

export const defaultItemCarouselState: ItemCarouselState = {
    byId: {},
    loading: false,
};
