import { ApiHosts, handleResponseOld, makeGet } from '@/redux/api/helpers';
import { HomePageData } from '@/types/Home';
import getSubFromToken from '@/utils/getSubFromToken';

/**
 * @category Item API
 * @see fetchHomeData
 */
type FetchHomeDataParams = {
    authToken: string;
    deployment: string;
    forceUpdate: boolean;
};
type FetchHomeDataResponse = {
    data: HomePageData;
    message: string;
    success: boolean;
};

/**
 * fetches Home page data via Get request to Item API
 * @function fetchHomeData
 * @category Item API
 * @param {FetchHomeDataParams}
 * @returns {FetchHomeDataResponse}
 * @see https://item-api-DEPLOYMENT.liveauctioneers.com/spapage/home
 * @see https://github.com/LIVEauctioneers/item-api/blob/master/src/react-site/routes/get-spa-home-page.ts
 */
export const fetchHomeData = ({ authToken, deployment, forceUpdate }: FetchHomeDataParams) =>
    new Promise<FetchHomeDataResponse>((resolve, reject) => {
        const bidderId = getSubFromToken(authToken);
        let request: ReturnType<typeof makeGet>;
        const query: Record<string, string | number> = {};
        if (bidderId) {
            request = makeGet({
                apiPath: 'spapage/home/new',
                authToken,
                deployment,
                path: ApiHosts.ItemApi,
            });
            query.bidderId = bidderId;
        } else {
            request = makeGet({
                apiPath: 'spapage/home/new',
                deployment,
                path: ApiHosts.ItemApi,
            });
        }
        query.trendingLimit = 20;
        query.offset = new Date().getTimezoneOffset();

        if (forceUpdate) {
            query.bustLocalCache = Date.now();
        }
        request.query(query);
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });
