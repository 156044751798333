import { createAsyncThunk } from '@/redux/createAsyncThunk';
import { fetchHomeData } from '@/redux/modules/home/home.api';
import { fetchItemSummariesIfNeeded } from '@/redux/modules/item/summary/itemSummary.actions';
import { fetchRecommendedItemIfNeeded, getSortedRecommendedItemIdsFromState } from '@/redux/modules/recommendedItem';
import {
    fetchSavedSearchesIfNeeded,
    fetchSavedSearchItemsIfNeeded,
    getSavedSearchItemIds,
    hasSavedSearchesSelector,
} from '@/redux/modules/savedSearch';
import { fetchSellerUpcomingCatalogsIfNeeded } from '@/redux/modules/sellerUpcomingCatalogs';
import { fetchUpcomingCatalogsIfNeeded } from '@/redux/modules/upcomingCatalogs';
import { fetchUpcomingItemsIfNeeded } from '@/redux/modules/upcoming/items/upcomingItems.actions';
import { getAuthToken, isUserLoggedIn } from '@/redux/modules/account/user/user.selectors';
import { getDeployment } from '@/redux/modules/config';
import { getFollowedSellerIds } from '@/redux/modules/followSeller';
import { getUpcomingItemIds } from '@/redux/modules/upcoming/items/upcomingItems.selectors';
import { HomePageData } from '@/types/Home';
import { MAX_TODAY_AUCTIONS } from '@/redux/modules/home/home.types';
import { shouldFetchHomeData } from '@/redux/modules/home/home.selectors';

/**
 * @deprecated If using redux-toolkit's createSlice, use loadHomepageData.fulfilled
 */
export const LOAD_HOME_DATA_SUCCESS = 'la/domain/home/LOAD_SUCCESS';

export const loadHomeData = createAsyncThunk<HomePageData, boolean>(
    'la/ui/home/loadHomeData',
    async (forceUpdate, { dispatch, getState }) => {
        const state = getState();
        const deployment = getDeployment(state);
        const authToken = getAuthToken(state);

        const response = await fetchHomeData({
            authToken,
            deployment,
            forceUpdate,
        });
        dispatch({
            meta: { actionTime: Date.now() },
            payload: response.data,
            type: LOAD_HOME_DATA_SUCCESS,
        });
        return response.data;
    }
);

export const fetchHomeDataIfNeeded = createAsyncThunk<void, boolean>(
    'la/ui/home/fetchHomeDataIfNeeded',
    async (forceUpdate, { dispatch, getState }) => {
        if (forceUpdate || shouldFetchHomeData(getState())) {
            await dispatch(loadHomeData(forceUpdate));
        }
    }
);

export const fetchAllHomeData = createAsyncThunk<void, void>(
    'la/ui/home/fetchAllHomeData',
    async (_, { dispatch, getState }) => {
        const state = getState();
        const hasSavedSearches = hasSavedSearchesSelector(state);
        const followedSellerIds = getFollowedSellerIds(state);
        await Promise.all([
            dispatch(fetchHomeDataIfNeeded(false)),
            dispatch(fetchUpcomingItemsIfNeeded()),
            dispatch(fetchUpcomingCatalogsIfNeeded(MAX_TODAY_AUCTIONS, true)),
            dispatch(fetchSavedSearchesIfNeeded()),
            dispatch(fetchSavedSearchItemsIfNeeded(hasSavedSearches)),
            dispatch(fetchRecommendedItemIfNeeded(false)),
            ...followedSellerIds.map((sellerId) => dispatch(fetchSellerUpcomingCatalogsIfNeeded(sellerId))),
        ]);
        const newState = getState();
        const isLoggedIn = isUserLoggedIn(newState);

        if (isLoggedIn) {
            const upcomingItemIds = getUpcomingItemIds(newState);
            const savedSearchItemIds = getSavedSearchItemIds(newState);
            const recommendedItemIds = getSortedRecommendedItemIdsFromState(newState, 12);
            const promises: Promise<any>[] = [];
            promises.push(
                dispatch(
                    fetchItemSummariesIfNeeded({
                        identifier: 'COMING-UP-Carousel',
                        itemIds: upcomingItemIds,
                    })
                )
            );
            promises.push(
                dispatch(
                    fetchItemSummariesIfNeeded({
                        identifier: 'FOLLOWED-SEARCH-Carousel',
                        itemIds: savedSearchItemIds,
                    })
                )
            );
            promises.push(
                dispatch(
                    fetchItemSummariesIfNeeded({
                        identifier: 'RECOMMENDED-ITEM-Carousel',
                        itemIds: recommendedItemIds,
                    })
                )
            );
            await Promise.all(promises);
        }
    }
);
