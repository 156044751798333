import { createSlice } from '@reduxjs/toolkit';
import { defaultHomeSlice } from '@/redux/modules/home/home.types';
import { loadHomeData } from './home.actions';
import shuffle from 'lodash/shuffle';

const homeSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(loadHomeData.pending, (slice) => {
            slice.loading = true;
        });
        builder.addCase(loadHomeData.fulfilled, (slice, { payload }) => {
            let catalogIds: number[] = [];
            if (Array.isArray(payload.featuredCatalogs)) {
                if (Number.isInteger(payload.featuredCatalogs[0])) {
                    // old way, an array of ints
                    catalogIds.push(...payload.featuredCatalogs);
                } else {
                    // TODO: What type is x supposed to be in this case?
                    // new way, an array of objects
                    catalogIds.push(...payload.featuredCatalogs.map((x: any) => x.catalogId));
                }
            }
            slice.featuredCatalogs = shuffle(catalogIds);
            slice.acn = payload.acn;
            slice.featuredAuctioneers = payload.featuredAuctioneers;
            slice.loaded = Date.now();
            slice.loading = false;
            slice.trendingItems = payload.trendingItems.slice(0, 12);
            slice.upcomingCatalogs = payload.upcomingCatalogs;
        });
        builder.addCase(loadHomeData.rejected, (slice) => {
            slice.loading = false;
        });
    },
    initialState: defaultHomeSlice,
    name: 'home',
    reducers: {},
});

export const { reducer: homeReducer } = homeSlice;
