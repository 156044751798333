import { BiddingInfo, EmptyBiddingInfo } from '@/types/BiddingInfo';
import { BuyNow, EmptyBuyNow } from '@/types/BuyNow';
import { BuyNowStatus, emptyItemSummary, ItemSummary } from '@/types/item/ItemSummary';
import { Catalog, EmptyCatalog } from '@/types/Catalog';
import { createSlice } from '@reduxjs/toolkit';
import { defaultItemSummarySlice } from '@/redux/modules/item/summary/itemSummary.types';
import { EmptySeller, Seller } from '@/types/Seller';
import { fetchAllBidderBiddingInfo } from '@/redux/modules/bidder/biddingInfoAll/bidderBiddingInfoAll.actions';
import { fetchBidderData } from '@/redux/modules/account/user/user.actions';
import { fetchCatalogItemSummaries } from '@/redux/modules/catalog/items/catalogItems.actions';
import {
    fetchItemSummaries,
    LOAD_ITEM_DATA_FROM_ELSEWHERE_SUCCESS,
    LoadItemDataFromElsewhereId,
} from '@/redux/modules/item/summary/itemSummary.actions';
import { fetchSavedItems } from '@/redux/modules/item/saved/savedItems.actions';
import { FulfilledActionFromAsyncThunk } from '@/redux/createAsyncThunk';
import { getNewPage, getPriceResults } from '@/redux/modules/priceResults/priceResults.actions';
import { Item } from '@/types/item/Item';
import { ItemSummarySlice } from './itemSummary.types';
import {
    LIVE_BID_ACCEPTED,
    LIVE_BID_PLACED,
    LIVE_BID_RETRACTED,
    LIVE_LOT_PASSED,
    LIVE_LOT_PASSED_ACTION,
    LIVE_LOT_REOPENED,
    LIVE_LOT_REOPENED_ACTION,
    LIVE_LOT_SOLD,
    LIVE_LOT_SOLD_ACTION,
    LIVE_LOT_UNSOLD,
    LIVE_LOT_UNSOLD_ACTION,
    LIVE_NEXT_LOT_LOADED,
    LIVE_NEXT_LOT_LOADED_ACTION,
    LiveBidAcceptedAction,
    LiveBidPlacedAction,
    LiveBidRetractedAction,
    LOAD_CATEGORY_RESULTS_SUCCESS,
    LOAD_CATEGORY_RESULTS_SUCCESS_ACTION,
    LOAD_LIVE_CATALOG_STATUS_SUCCESS,
    LOAD_LIVE_CATALOG_STATUS_SUCCESS_ACTION,
    LOAD_SIMILAR_ITEMS_SUCCESS,
    LOAD_SIMILAR_ITEMS_SUCCESS_ACTION,
    LOAD_SIMILAR_SOLD_ITEMS_SUCCESS,
    LOAD_SIMILAR_SOLD_ITEMS_SUCCESS_ACTION,
    LOAD_STOREFRONT_ITEMS_SUCCESS,
} from '../../actions';
import {
    LOAD_PROMOTED_SEARCH_RESULTS_SUCCESS,
    LoadPromotedSearchResultsSuccessAction,
} from '@/redux/modules/searchPromoted';
import { loadHomeData } from '@/redux/modules/home/home.actions';
import { loadItemCarousels } from '../../itemCarousel/itemCarousel.actions';
import { loadUserPlacedBidItemSummaries } from '../userBidPlacedItems/userBidPlacedItems.actions';
import { LOG_OUT_BIDDER, LogOutBidderSuccessAction } from '@/redux/modules/account/logout/logout.actions';
import { safelyEscapeText } from '@/utils/safelyRender';
import { SearchResultPayload } from '@/redux/modules/search/search.types';
import { submitAbsenteeBid } from '@/redux/modules/absenteeBid/absenteeBid.actions';
import { submitLogin, submitLoginWithToken } from '@/redux/modules/account/login/login.actions';
import { submitRetractBid } from '@/redux/modules/retractBid/retractBid.actions';
import { submitSearch } from '@/redux/modules/search/search.actions';
import difference from 'lodash/difference';
import union from 'lodash/union';

// region DRY reducers
const loadItemsUpdateSlice = (slice: ItemSummarySlice, summaries: ItemSummary[]): ItemSummarySlice => {
    const actionTime = Date.now();
    summaries.forEach((item) => {
        const { itemId } = item;
        slice.byId[itemId] = {
            ...slice.byId[itemId],
            ...item,
            catalogTitle: safelyEscapeText(item.catalogTitle),
            photos: item.photos || [],
            sellerName: safelyEscapeText(item.sellerName),
            shippingProviderId: item?.shippingProviderId || slice.byId[itemId]?.shippingProviderId || 0,
            shortDescription: safelyEscapeText(item.shortDescription),
            title: safelyEscapeText(item.title),
        };
        slice.loaded[itemId] = actionTime;
        slice.loading = difference(slice.loading, [itemId]);
    });

    // This TODO is from before toolkit conversion. Let's keep it here juuuust in case.
    // TODO: Do we cleanup?
    // const now = Date.now();
    // let toCleanup = Object.keys(loaded)
    //     .map((key) => ({ id: key, ts: loaded[key] }))
    //     .filter(({ ts }) => now - ts > REDUX_CLEANUP_LIMIT)
    //     .map(({ id }) => id);
    // existing = omit(existing, toCleanup);
    // loaded = omit(loaded, toCleanup);

    return slice;
};

const loadItemsReducer = (
    slice: ItemSummarySlice,
    {
        payload,
    }:
        | FulfilledActionFromAsyncThunk<typeof fetchItemSummaries>
        | LoadUserPlaceBidItemSummariesAction
        | LOAD_SIMILAR_ITEMS_SUCCESS_ACTION
        | LOAD_SIMILAR_SOLD_ITEMS_SUCCESS_ACTION
): ItemSummarySlice => loadItemsUpdateSlice(slice, payload);

const loadItemsFromElsewhereReducer = (
    slice: ItemSummarySlice,
    payload: {
        biddingInfos?: BiddingInfo[];
        buyNows?: BuyNow[];
        catalogs?: Catalog[];
        items?: Item[];
        sellers?: Seller[];
    }
): ItemSummarySlice => {
    const { biddingInfos = [], buyNows = [], catalogs = [], items = [], sellers = [] } = payload;
    items.forEach((item) => {
        const catalog = catalogs.find((c) => c.catalogId === item.catalogId) || EmptyCatalog;
        const seller = sellers.find((s) => s.sellerId === item.sellerId) || EmptySeller;
        const buyNow = buyNows.find((i) => i.itemId === item.itemId) || EmptyBuyNow;
        const biddingInfo = biddingInfos.find((i) => i.itemId === item.itemId) || EmptyBiddingInfo;

        slice.byId[item.itemId] = {
            ...emptyItemSummary,
            ...slice.byId[item.itemId],
            bidActivityCount: biddingInfo.bidActivityCount || 0,
            bidCount: biddingInfo.bidCount || 0,
            bidderBidTimestamp: biddingInfo.bidderBidTimestamp,
            bidderHasBid: biddingInfo.bidderHasBid,
            bidderHasHighBid: biddingInfo.bidderHasHighBid,
            bidderMaxBid: biddingInfo.bidderMaxBid,
            bidderWonItem: biddingInfo.bidderHasHighBid && biddingInfo.isSold,
            buyNowPrice: buyNow.price,
            buyNowStatus: buyNow.status === 'available' ? BuyNowStatus.Available : BuyNowStatus.Unavailable,
            catalogId: item.catalogId,
            catalogStatus: catalog.catalogStatus,
            catalogTitle: safelyEscapeText(catalog.title),
            currency: catalog.currency || 'USD',
            highBidEstimate: item.highBidEstimate || 0,
            imageVersion: item.imageVersion,
            index: item.index,
            isAvailable: biddingInfo.isAvailable,
            isCatalogOnly: catalog.isCatalogOnly,
            isDeleted: item.isDeleted,
            isLiveAuction: !catalog.isCatalogOnly && !catalog.isTimed,
            isLocked: biddingInfo.isLocked,
            isPassed: biddingInfo.isPassed,
            isPaused: biddingInfo.isPaused,
            isReserveMet: biddingInfo.isReserveMet,
            isSEOExcluded: item.isSEOExcluded,
            isSold: biddingInfo.isSold,
            isTimedAuction: catalog.isTimed,
            itemId: item.itemId,
            leadingBid: biddingInfo.leadingBid,
            lotNumber: item.lotNumber,
            lotsListed: catalog.lotsListed,
            lowBidEstimate: item.lowBidEstimate || 0,
            photos: item.photos || [],
            salePrice: biddingInfo.salePrice,
            saleStartTs: catalog.saleStartTs,
            sellerCity: seller.city,
            sellerCountryCode: seller.countryCode,
            sellerId: seller.sellerId,
            sellerLogoId: seller.logoId || '',
            sellerName: safelyEscapeText(seller.name),
            sellerStateCode: seller.stateCode,
            shortDescription: safelyEscapeText(item.descriptionBlurb),
            startPrice: item.startPrice,
            title: safelyEscapeText(item.title),
        };

        slice.loaded[item.itemId] = Date.now();
    });
    return slice;
};

const loadBidderReducer = (
    slice: ItemSummarySlice,
    action:
        | FulfilledActionFromAsyncThunk<typeof fetchBidderData>
        | FulfilledActionFromAsyncThunk<typeof submitLogin>
        | FulfilledActionFromAsyncThunk<typeof submitLoginWithToken>
): ItemSummarySlice => {
    action.payload.biddingInfos.forEach(
        ({
            bidActivityCount,
            bidCount,
            bidderBidTimestamp,
            bidderHasBid,
            bidderHasHighBid,
            bidderMaxBid,
            isAvailable,
            isLocked,
            isPassed,
            isPaused,
            isReserveMet,
            isSold,
            itemId,
            leadingBid,
            salePrice,
        }) => {
            slice.byId[itemId] = {
                ...emptyItemSummary,
                ...slice.byId[itemId],
                bidActivityCount,
                bidCount,
                bidderBidTimestamp,
                bidderHasBid,
                bidderHasHighBid,
                bidderMaxBid,
                isAvailable,
                isLocked,
                isPassed,
                isPaused,
                isReserveMet,
                isSold,
                itemId,
                leadingBid,
                salePrice,
            };
            slice.loaded[itemId] = Date.now();
        }
    );
    return slice;
};

const liveBidStatusChangeReducer = (
    slice: ItemSummarySlice,
    { payload }: LiveBidAcceptedAction | LiveBidRetractedAction
): ItemSummarySlice => {
    const { amount, bidCount, endTime, isExtended, isReserveMet, itemId, myBid } = payload;
    const currentItem = slice.byId[itemId] || emptyItemSummary;
    slice.byId[itemId] = {
        ...currentItem,
        bidActivityCount: Math.max(currentItem.bidActivityCount, bidCount),
        bidCount,
        bidderHasHighBid: myBid,
        bidderIsPlacingBid: false, // changing to false now that the bid has been accepted/retracted
        isExtended: isExtended ?? false,
        isReserveMet,
        leadingBid: amount,
        lotEndTimeEstimatedTs: Boolean(endTime) ? new Date(endTime).getTime() / 1000 : 0,
    };
    return slice;
};

type LoadUserPlaceBidItemSummariesAction = {
    payload: ItemSummary[];
};

type ItemSummarySearchResultType =
    | FulfilledActionFromAsyncThunk<typeof submitSearch>
    | LOAD_CATEGORY_RESULTS_SUCCESS_ACTION
    | LoadPromotedSearchResultsSuccessAction;

const loadSearchResultsReducer = (slice: ItemSummarySlice, action: ItemSummarySearchResultType): ItemSummarySlice => {
    // a few of these types can come back as a string,
    // so we need to make sure it's an array of items
    // before updating the store
    const payload = action.payload as SearchResultPayload;
    if (payload.items?.length > 0) {
        return loadItemsUpdateSlice(slice, payload.items);
    }
    return slice;
};
// endregion

const itemSummarySlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(fetchItemSummaries.pending, (slice, { meta }) => {
            slice.loading = union(slice.loading, meta.arg.itemIds);
        });
        builder.addCase(fetchItemSummaries.fulfilled, loadItemsReducer);
        builder.addCase(fetchItemSummaries.rejected, (slice, { meta }) => {
            slice.loading = difference(slice.loading, meta.arg.itemIds);
        });

        // region "my bids" items
        builder.addCase(loadUserPlacedBidItemSummaries.fulfilled, (slice, { payload }) => {
            const actionPayload: LoadUserPlaceBidItemSummariesAction = {
                payload: payload.items,
            };
            return loadItemsReducer(slice, actionPayload);
        });
        // endregion

        // region Load items
        builder.addCase(fetchCatalogItemSummaries.fulfilled, (slice, { payload }) =>
            loadItemsReducer(slice, { payload: payload.items })
        );
        builder.addCase(fetchSavedItems.fulfilled, (slice, { payload }) => loadItemsUpdateSlice(slice, payload.items));
        builder.addCase<typeof LOAD_SIMILAR_ITEMS_SUCCESS, LOAD_SIMILAR_ITEMS_SUCCESS_ACTION>(
            LOAD_SIMILAR_ITEMS_SUCCESS,
            loadItemsReducer
        );
        builder.addCase<typeof LOAD_SIMILAR_SOLD_ITEMS_SUCCESS, LOAD_SIMILAR_SOLD_ITEMS_SUCCESS_ACTION>(
            LOAD_SIMILAR_SOLD_ITEMS_SUCCESS,
            loadItemsReducer
        );
        builder.addCase(loadHomeData.fulfilled, (slice, { payload }) => loadItemsFromElsewhereReducer(slice, payload));
        builder.addCase<LoadItemDataFromElsewhereId, LOAD_ITEM_DATA_FROM_ELSEWHERE_SUCCESS>(
            LOAD_STOREFRONT_ITEMS_SUCCESS,
            (slice, { payload }) => loadItemsFromElsewhereReducer(slice, payload)
        );
        // endregion

        // region Live bidding
        builder.addCase<typeof LIVE_LOT_PASSED, LIVE_LOT_PASSED_ACTION>(LIVE_LOT_PASSED, (slice, action) => {
            const { itemId } = action.payload;
            const currentItem = slice.byId[itemId] || emptyItemSummary;
            slice.byId[itemId] = {
                ...currentItem,
                isAvailable: false,
                isLocked: true,
                isPassed: true,
                isPaused: false,
                isSold: false,
            };
        });
        builder.addCase<typeof LIVE_LOT_REOPENED, LIVE_LOT_REOPENED_ACTION>(LIVE_LOT_REOPENED, (slice, action) => {
            const { itemId } = action.payload;
            const currentItem = slice.byId[itemId] || emptyItemSummary;
            slice.byId[itemId] = {
                ...currentItem,
                isAvailable: true,
                isPassed: false,
                isPaused: false,
                isSold: false,
                salePrice: 0,
            };
        });
        builder.addCase<typeof LIVE_LOT_SOLD, LIVE_LOT_SOLD_ACTION>(LIVE_LOT_SOLD, (slice, action) => {
            const { amount, itemId, myBid } = action.payload;
            const currentItem = slice.byId[itemId] || emptyItemSummary;
            slice.byId[itemId] = {
                ...currentItem,
                bidderHasBid: myBid || currentItem.bidderHasBid,
                bidderHasHighBid: myBid,
                bidderWonItem: myBid,
                isAvailable: false,
                isLocked: true,
                isPassed: false,
                isPaused: false,
                isSold: true,
                leadingBid: amount,
                salePrice: amount,
            };
        });
        builder.addCase<typeof LIVE_LOT_UNSOLD, LIVE_LOT_UNSOLD_ACTION>(LIVE_LOT_UNSOLD, (slice, action) => {
            const { itemId } = action.payload;
            const currentItem = slice.byId[itemId] || emptyItemSummary;
            slice.byId[itemId] = {
                ...currentItem,
                isAvailable: false,
                isLocked: true,
                isPassed: false,
                isPaused: false,
                isSold: false,
            };
        });
        builder.addCase<typeof LIVE_NEXT_LOT_LOADED, LIVE_NEXT_LOT_LOADED_ACTION>(
            LIVE_NEXT_LOT_LOADED,
            (slice, action) => {
                const { itemId } = action.payload;
                const currentItem = slice.byId[itemId] || emptyItemSummary;
                slice.byId[itemId] = {
                    ...currentItem,
                    isLocked: true,
                };
            }
        );
        builder.addCase<typeof LOAD_LIVE_CATALOG_STATUS_SUCCESS, LOAD_LIVE_CATALOG_STATUS_SUCCESS_ACTION>(
            LOAD_LIVE_CATALOG_STATUS_SUCCESS,
            (slice, { meta, payload }) => {
                const { status } = payload;
                if (status !== 'notLoaded') {
                    // region auction's live lot
                    const {
                        itemId: liveId,
                        leadingBid: liveLeadingBid,
                        leadingBidder: liveLeadingBidder,
                    } = payload.currentItem;

                    const isUserLeading = meta.bidderId === liveLeadingBidder;
                    const currentItem = slice.byId[liveId] || emptyItemSummary;

                    slice.byId[liveId] = {
                        ...currentItem,
                        bidderHasBid: isUserLeading || currentItem.bidderHasBid,
                        bidderHasHighBid: isUserLeading,
                        leadingBid: liveLeadingBid,
                    };
                    // endregion

                    // region user's viewed item
                    if (payload.viewedItem) {
                        const { askPrice, currentBid, endTime, isExtended, lotId: viewedId } = payload.viewedItem;
                        slice.byId[viewedId] = {
                            ...(slice.byId[viewedId] || emptyItemSummary),
                            isExtended: isExtended,
                            leadingBid: currentBid,
                            saleEndTs: Boolean(endTime) ? new Date(endTime).getTime() / 1000 : 0,
                            startPrice: askPrice,
                        };
                    }
                    // endregion
                }
            }
        );
        builder.addCase<typeof LIVE_BID_ACCEPTED, LiveBidAcceptedAction>(LIVE_BID_ACCEPTED, liveBidStatusChangeReducer);
        builder.addCase<typeof LIVE_BID_PLACED, LiveBidPlacedAction>(LIVE_BID_PLACED, (slice, { payload }) => {
            if (payload.currentUserPlacedBid) {
                const { amount, lotId } = payload;
                const currentItem = slice.byId[lotId] || emptyItemSummary;
                slice.byId[lotId] = {
                    ...currentItem,
                    bidderHasBid: true,
                    bidderIsPlacingBid: true,
                    bidderMaxBid: amount,
                };
            }
        });
        builder.addCase<typeof LIVE_BID_RETRACTED, LiveBidRetractedAction>(
            LIVE_BID_RETRACTED,
            liveBidStatusChangeReducer
        );
        // endregion

        // region Bidder updates
        builder.addCase(fetchBidderData.fulfilled, loadBidderReducer);
        builder.addCase(submitLogin.fulfilled, loadBidderReducer);
        builder.addCase(submitLoginWithToken.fulfilled, loadBidderReducer);
        builder.addCase(fetchAllBidderBiddingInfo.fulfilled, (slice, { payload }) => {
            const items = [...payload.upcomingBidItems, ...payload.pastBidItems];
            items.forEach(
                ({
                    bidActivityCount,
                    bidCount,
                    bidderBidTimestamp,
                    bidderHasBid,
                    bidderHasHighBid,
                    bidderMaxBid,
                    isAvailable,
                    isLocked,
                    isPassed,
                    isPaused,
                    isReserveMet,
                    isSold,
                    itemId,
                    leadingBid,
                    salePrice,
                }) => {
                    slice.byId[itemId] = {
                        ...emptyItemSummary,
                        ...slice.byId[itemId],
                        bidActivityCount,
                        bidCount,
                        bidderBidTimestamp,
                        bidderHasBid,
                        bidderHasHighBid,
                        bidderMaxBid,
                        bidderWonItem: bidderHasHighBid && isSold,
                        isAvailable,
                        isLocked,
                        isPassed,
                        isPaused,
                        isReserveMet,
                        isSold,
                        itemId,
                        leadingBid,
                        salePrice,
                    };
                    slice.loaded[itemId] = Date.now();
                }
            );
        });
        builder.addCase<typeof LOG_OUT_BIDDER, LogOutBidderSuccessAction>(LOG_OUT_BIDDER, (slice) => {
            const itemIds = Object.keys(slice.byId);
            itemIds.forEach((itemId) => {
                const currentItem = slice.byId[itemId];
                slice.byId[itemId] = {
                    ...currentItem,
                    bidderBidTimestamp: 0,
                    bidderHasBid: false,
                    bidderHasHighBid: false,
                    bidderMaxBid: 0,
                    bidderWonItem: false,
                };
            });
        });
        // endregion

        // region Search results
        builder.addCase(submitSearch.fulfilled, loadSearchResultsReducer);
        builder.addCase<typeof LOAD_CATEGORY_RESULTS_SUCCESS, LOAD_CATEGORY_RESULTS_SUCCESS_ACTION>(
            LOAD_CATEGORY_RESULTS_SUCCESS,
            loadSearchResultsReducer
        );
        builder.addCase<typeof LOAD_PROMOTED_SEARCH_RESULTS_SUCCESS, LoadPromotedSearchResultsSuccessAction>(
            LOAD_PROMOTED_SEARCH_RESULTS_SUCCESS,
            loadSearchResultsReducer
        );
        // endregion

        // region One-off reducers
        builder.addCase(loadItemCarousels.fulfilled, (slice, { payload }) => {
            payload.carousels.forEach((carousel) => {
                carousel.items.forEach((item) => {
                    slice.byId[item.itemId] = {
                        ...slice.byId[item.itemId],
                        ...item,
                    };
                });
            });
        });

        builder.addCase(submitAbsenteeBid.fulfilled, (slice, { meta, payload: { payload } }) => {
            const { currentBid, isCurrentHighBid, isReserveMet, leadingBid } = payload;
            const currentItem = slice.byId[meta.arg.absenteeBidDetails.itemId] || emptyItemSummary;
            slice.byId[meta.arg.absenteeBidDetails.itemId] = {
                ...currentItem,
                bidActivityCount: currentItem.bidderHasHighBid
                    ? currentItem.bidActivityCount
                    : currentItem.bidActivityCount + 1,
                bidderBidTimestamp: Date.now(),
                bidderHasBid: true,
                bidderHasHighBid: isCurrentHighBid,
                bidderMaxBid: Number(currentBid),
                isReserveMet,
                leadingBid,
            };
        });

        builder.addCase(submitRetractBid.fulfilled, (slice, { payload }) => {
            slice.byId[payload.itemId] = {
                ...slice.byId[payload.itemId],
                bidderHasBid: false,
                bidderHasHighBid: false,
                bidderMaxBid: 0,
            };
        });

        builder.addCase(getPriceResults.fulfilled, (slice, { payload }) => {
            const { salesHistoryItems, upcomingSalesItems } = payload;
            salesHistoryItems.forEach((saleHistoryItem) => {
                slice.byId[saleHistoryItem.itemId] = {
                    ...slice.byId[saleHistoryItem.itemId],
                    ...saleHistoryItem,
                    shippingProviderId:
                        saleHistoryItem.shippingProviderId ||
                        slice.byId[saleHistoryItem.itemId]?.shippingProviderId ||
                        0,
                };
            });
            upcomingSalesItems.forEach((upcomingSaleItem) => {
                slice.byId[upcomingSaleItem.itemId] = {
                    ...slice.byId[upcomingSaleItem.itemId],
                    ...upcomingSaleItem,
                    shippingProviderId:
                        upcomingSaleItem.shippingProviderId ||
                        slice.byId[upcomingSaleItem.itemId]?.shippingProviderId ||
                        0,
                };
            });
        });

        builder.addCase(getNewPage.fulfilled, (slice, { payload }) => {
            const { salesHistoryItems, upcomingSalesItems } = payload;
            salesHistoryItems.forEach((saleHistoryItem) => {
                slice.byId[saleHistoryItem.itemId] = {
                    ...slice.byId[saleHistoryItem.itemId],
                    ...saleHistoryItem,
                    shippingProviderId:
                        saleHistoryItem?.shippingProviderId ||
                        slice.byId[saleHistoryItem.itemId]?.shippingProviderId ||
                        0,
                };
            });
            upcomingSalesItems.forEach((upcomingSaleItem) => {
                slice.byId[upcomingSaleItem.itemId] = {
                    ...slice.byId[upcomingSaleItem.itemId],
                    ...upcomingSaleItem,
                    shippingProviderId:
                        upcomingSaleItem?.shippingProviderId ||
                        slice.byId[upcomingSaleItem.itemId]?.shippingProviderId ||
                        0,
                };
            });
        });
        // endregion
    },
    initialState: defaultItemSummarySlice,
    name: 'itemSummary',
    reducers: {},
});

export const { reducer: itemSummaryReducer } = itemSummarySlice;
